import styled from '@emotion/styled';

const IntineraryEventStyle = styled('div')`
    /* margin-bottom: 16px; */
    .titleFightPlan {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
    .mainImage {
        border-radius: 6px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .imageEvent {
        width: 100%;
        height: 223px;
        border-radius: 8px;
        object-fit: cover;
    }
    .nameEventTicket {
        background-color: #eef9ff;
        border-radius: 8px;
        /* height: 120px; */
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        margin-bottom: 20px;
    }
    .headerTicket{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .bodyTicket{
        margin-left: 20px;
    }
    .contentEvent{
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.14px;
        list-style-type: disc;
    }
    .iconCup {
        color: #12b897;
    }
    .titleTicket {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 700;
        line-height: 24.038px;
    }
    .freeDay {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        height: 120px;
        margin-bottom: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
    }
    .titleFreeDay {
        color: #000;
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 700;
    }
`;

export default IntineraryEventStyle;
