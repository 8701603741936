import React, { useState } from 'react';
import CarHideCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';

interface carHideCardProps {
    dataCarHide?: any;
    lastItem?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isloading?: boolean;
}

const CarHideCardComponent: React.FC<carHideCardProps> = ({
    dataCarHide,
    lastItem,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isloading,
}) => {
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const carHireName = dataCarHide?.name || '';
    const pickUp = dataCarHide?.pickUp || null;
    const dropOff = dataCarHide?.dropOff || null;
    const timePickUp = pickUp?.time==='00:00'?'time TBC':pickUp?.time || '';
    const timeDropUp = dropOff?.time==='00:00'?'time TBC':dropOff?.time || '';
    const carType = dataCarHide?.carType || '';
    const NumOfPassenger = dataCarHide?.passenger || 0;
    const nightOfCarHire = dataCarHide?.nights || 0;
    const agentComments = dataCarHide?.agentComments || [];
    const itemId = dataCarHide?.id;
    const direction = dataCarHide?.direction || '';

    return (
        <CarHideCardStyle mode={isRole}>
            <div>
                <div className="traditionView">
                    <div className="viewDetailPlanFlight">
                        <div className="headerCard">
                            {direction === 'Drop Off' ? (
                                <div className="titleFightPlan">
                                    Drop Off Your Hire Car
                                </div>
                            ) : (
                                <div className="titleFightPlan">
                                    {carHireName}
                                </div>
                            )}
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faCar}
                            />
                        </div>
                        <div>
                            <div className="detailPlanflight">
                                <FontAwesomeIcon
                                    className="iconTransportationDiscript"
                                    icon={faCar}
                                />
                                <div className="locationName">
                                    {direction === 'Drop Off'
                                        ? 'Drop off'
                                        : 'Pick up'}{' '}
                                    vehicle{' '}
                                    {direction === 'Drop Off' ? 'to' : 'from'}{' '}
                                    {direction === 'Drop Off'
                                        ? dropOff?.location
                                        : pickUp?.location}{' '}
                                    at {timePickUp}
                                    {timeDropUp}
                                    {direction === 'Drop Off'
                                        ? ''
                                        : nightOfCarHire > 1
                                        ? `, ${nightOfCarHire} days`
                                        : `, ${nightOfCarHire} day`}
                                </div>
                            </div>
                        </div>
                        <div className="viewFlightinformation">
                            <div className="textFont">Car type: {carType}</div>
                            <div className="textFont">{NumOfPassenger} pax</div>
                        </div>
                    </div>
                </div>
                <div
                    className="iconAddComment"
                    onClick={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                >
                    <img alt="" className="iconAdd" src={icons.addComment} />
                </div>
            </div>
            {(agentComments?.length > 0 || showEditAgentComment) &&
                agentComments?.map((item: any, index: any) => {
                    return (
                        <AgentCommentComponent
                            key={index}
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="CarHire"
                            dataAgentComment={item}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isloading}
                        />
                    );
                })}
            {agentComments?.length === 0 && showEditAgentComment && (
                <AgentCommentComponent
                    showEdit={showEditAgentComment}
                    itemId={itemId}
                    typeOfComent="CarHire"
                    dataAgentComment={null}
                    avatarAgent={avatarAgent}
                    changeShowEdit={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                    refetch={refetchApi}
                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                    quoteTitle={quoteTitle}
                    isLoading={isloading}
                />
            )}
        </CarHideCardStyle>
    );
};

export default CarHideCardComponent;
