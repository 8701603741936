import React from 'react';
import StepComponentStyle from './style';
import icons from 'src/assets/icon';
import { useLocation } from 'react-router-dom';
import { formatPhoneNumber } from 'src/const/contain';

interface stepProps {
    phone?: any;
}

const StepComponent: React.FC<stepProps> = ({ phone }) => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const eventRedirectToPay = () => {
        window.open(
            `${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${id}`,
            '_blank',
        );
    };

    const phoneCall = () => {
        window.location.href = `tel:${phone || '08000436644'}`;
    };

    return (
        <StepComponentStyle>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step01} />
                <div className="contentStep">
                    <div className="nextStepTitle">Any questions?</div>
                    <div className="nextStepDiscription">
                        Call me on{' '}
                        <span className="link" onClick={phoneCall}>
                            {phone
                                ? formatPhoneNumber(phone)
                                : formatPhoneNumber('08000436644')}
                        </span>
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step02} />
                <div className="contentStep">
                    <div className="nextStepTitle">Ready to Book?</div>
                    <div className="nextStepDiscription">
                        <span className="link" onClick={eventRedirectToPay}>
                            Pay your deposit here{' '}
                        </span>
                        to secure this quote.
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step03} />
                <div className="contentStep">
                    <div className="nextStepTitle">
                        Need to amend this Quote?
                    </div>
                    <div className="nextStepDiscription">
                        <span className="link">Let me know what you need,</span>{' '}
                        and I'll start working on it for you.
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.finalStep} />
                <div className="contentStep">
                    <div className="nextStepTitle">
                        This quote is only valid for 48 hours.
                    </div>
                    <div className="nextStepDiscription">
                        Availability is limited, so act quickly to secure your
                        trip!
                    </div>
                </div>
            </div>
        </StepComponentStyle>
    );
};

export default StepComponent;
