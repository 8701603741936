import React from 'react';
import LinkScrollItineraryStyle from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getItinerary } from 'src/api/apiGetItinerary';
import moment from 'moment';
import { fillMissingDates } from 'src/const/contain';

const ItineraryScrollComponent = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const idItinerary = queryParams.get('id') || undefined;

    const { data: dataItinerary } = useQueryApi(
        ['idItinerary', idItinerary],
        () => getItinerary(idItinerary),
        {
            enabled: !!idItinerary,
        },
    );

    const data = (dataItinerary as { itinerary: any })?.itinerary;
    const dataEvent = fillMissingDates(data);

    const handleScroll = (id: any) => {
        const event = new CustomEvent('scrollToSectionId', { detail: { id } });
        window.dispatchEvent(event);
    };

    return (
        <div>
            {!dataEvent ? null : (
                <LinkScrollItineraryStyle>
                    <div className="header">See Itinerary by Day</div>
                    <div className="body">
                        {Array.isArray(dataEvent) &&
                            dataEvent?.map((item: any, index: any) => {
                                const order = index + 1;
                                return (
                                    <div
                                        className="link"
                                        onClick={() => handleScroll(order)}
                                        key={order}
                                    >
                                        Day {order} -{' '}
                                        {moment(item?.date, 'YYYY-MM-D').format(
                                            'ddd Do MMMM YYYY',
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </LinkScrollItineraryStyle>
            )}
        </div>
    );
};

export default ItineraryScrollComponent;
