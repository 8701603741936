export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber?.replace(/\D/g, '');
    if (cleaned?.length !== 11) {
        return 'Invalid phone number';
    }
    const formattedNumber = cleaned?.replace(
        /(\d{4})(\d{3})(\d{4})/,
        '$1 $2 $3',
    );
    return formattedNumber;
};

export const processNumber = (num?: number): number => {
    if (num === undefined || num === null) {
        return 0;
    }

    if (num % 2 === 0) {
        return num;
    } else {
        return num - 1;
    }
};

export const formatCurrency = (amount: number| string): string => {
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    return numericAmount?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

interface GroupItem {
    group: string;
    items: any[];
}

export const sortGroupsByABC = (groups: GroupItem[]): GroupItem[] => {
    return groups.sort((a, b) => a.group.localeCompare(b.group));
};

export const getFirstLetter = (str: string) => {
    return str.charAt(0);
};

interface DayData {
    date: string;
    accommodations: any[];
    flights: any[];
    carHire: any | null;
    transfers: any[];
}

const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

const createEmptyDay = (date: string) => ({
    date,
    accommodations: [],
    flights: [],
    carHire: null,
    transfers: [],
});

export const fillMissingDates = (data: DayData[]): DayData[] => {
    if (!data || data.length === 0) return [];

    const startDate = new Date(data[0].date);
    const endDate = new Date(data[data.length - 1].date);

    const existingDatesSet = new Set(
        data.map((item) => formatDate(new Date(item.date)))
    );

    const filledData = [...data];

    let date = new Date(startDate);
    while (date <= endDate) {
        const dateStr = formatDate(date);

        if (!existingDatesSet.has(dateStr)) {
            filledData.push(createEmptyDay(dateStr));
        }

        date.setDate(date.getDate() + 1);
    }

    filledData.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return filledData;
};