import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import AgentCommentStyle from './style';
import TextArea from 'antd/es/input/TextArea';
import ModalComponent from '../modalCustom';
import ModalEditComment from './ModalconfirmEdit';
import { Popover, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';

interface AgentCommentProps {
    dataAgentComment?: any;
    showEdit?: boolean;
    itemId?: number | string;
    typeOfComent?: string;
    avatarAgent?: any;
    changeShowEdit?: () => void;
    refetch?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
}

const AgentCommentComponent: React.FC<AgentCommentProps> = ({
    dataAgentComment,
    showEdit,
    itemId,
    typeOfComent,
    avatarAgent,
    changeShowEdit,
    refetch,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
}) => {
    const [value, setValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [optionEvent, setOptionEvent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const isRole = useSelector(roleSelector);
    const itemType = typeOfComent;
    const dataComent = dataAgentComment?.comment;
    const idComent = dataAgentComment?.id;
    const handleDisable = value?.length || 0;

    useEffect(() => {
        setValue(dataComent);
        setLoading(Boolean(isLoading))
    }, [dataComent,isLoading]);

    const cancelEdit = () => {
        if (isRole === 'Edit mode' && dataComent) {
            changeShowEdit?.();
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const createComment = () => {
        if (value.length > 0) {
            setOptionEvent('Create');
            setIsModalVisible(true);
        }
    };

    const EditComent = () => {
        if (value.length > 0) {
            setOptionEvent('Edit');
            setIsModalVisible(true);
        }
    };

    const deleteComment = () => {
        setOptionEvent('Delete');
        setIsModalVisible(true);
    };

    const changeText = (e: any) => {
        const inputText = e.target.value;
        if (inputText.length <= 250) {
            setValue(inputText);
        }
    };

    return (
        <AgentCommentStyle mode={handleDisable}>
            {showEdit ? (
                <div className="reviewSellerAgentComent">
                    <div className="reviewSellerInput">
                        <div className="viewInputEdit">
                            <div className="areaMaxlenght">
                                <div className="viewMaxLenght">
                                    {handleDisable}/250
                                </div>
                            </div>
                            <TextArea
                                className="textAreaComment"
                                value={value}
                                onChange={(e) => changeText(e)}
                                placeholder="Type your message here..."
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                maxLength={250}
                            />
                        </div>
                        <div className="avatarSeller">
                            {avatarAgent ? (
                                <div className="backgroundAvatar">
                                    <img
                                        alt=""
                                        className="avatarReals"
                                        src={avatarAgent}
                                    />
                                </div>
                            ) : (
                                <FontAwesomeIcon
                                    className="avatar"
                                    icon={faCircleUser}
                                />
                            )}
                        </div>
                    </div>
                    <div className="btnEditComment">
                        <div className="btnLeft">
                            {dataComent ? (
                                <div
                                    className="btnSave"
                                    onClick={() => EditComent()}
                                >
                                    Save
                                </div>
                            ) : (
                                <div
                                    className="btnSave"
                                    onClick={() => createComment()}
                                >
                                    Save
                                </div>
                            )}
                            {dataComent && (
                                <div
                                    className="btnDelete"
                                    onClick={() => deleteComment()}
                                >
                                    Delete
                                </div>
                            )}
                        </div>

                        <div className="btnDelete" onClick={() => cancelEdit()}>
                            Cancel
                        </div>
                    </div>
                </div>
            ) : (
                <Spin spinning={loading} delay={500}>
                    <Popover
                        placement="rightTop"
                        trigger={
                            isRole === 'Edit mode' && dataComent && 'hover'
                        }
                        content={'Click to edit'}
                    >
                        <div
                            className="reviewSeller"
                            style={{
                                cursor:
                                    isRole === 'Edit mode' &&
                                    dataComent &&
                                    'pointer',
                            }}
                            onClick={cancelEdit}
                        >
                            <div className="viewDataComment">"{dataComent}"</div>
                            <div className="avatarSeller">
                                {avatarAgent ? (
                                    <div className="backgroundAvatar">
                                        <img
                                            alt=""
                                            className="avatarReals"
                                            src={avatarAgent}
                                        />
                                    </div>
                                ) : (
                                    <FontAwesomeIcon
                                        className="avatar"
                                        icon={faCircleUser}
                                    />
                                )}
                            </div>
                        </div>
                    </Popover>
                </Spin>
            )}
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={'Confirmation'}
                content={
                    <ModalEditComment
                        close={handleClose}
                        quoteTitle={quoteTitle}
                        changeShowEdit={changeShowEdit}
                        refetch={refetch}
                        roleBtnEditOnHeader={roleBtnEditOnHeader}
                        idItem={itemId}
                        idComment={idComent}
                        itemType={itemType}
                        commentValue={value}
                        optionEvent={optionEvent}
                        isloading={()=>setLoading(true)}
                    />
                }
            />
        </AgentCommentStyle>
    );
};

export default AgentCommentComponent;
