import styled from '@emotion/styled';

const ItemCaroselStyle = styled('div')`
    .titleItemCarouselMoldal {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-family: 'Lato';
    }
    .pointCarouselModal {
        border-radius: 4px;
        background: #12b897;
        padding: 5px 10px 5px 10px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
        margin-right: 13px;
        font-family: 'Lato';
    }
    .commentCarouselModal {
        color: #000;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
        letter-spacing: 0.16px;
        font-family: 'Lato';
    }
    .discriptionCarouselModal {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.16px;
        font-family: 'Lato';
    }
    .SignCarouselModal {
        color: #4f4f4f;
        text-align: right;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.16px;
        margin-top: 10px;
    }
    .modalDeskTop {
        display: flex;
    }
    .modalMobile {
        visibility: hidden;
    }
    /* .viewBtnSeeMore{
        display: flex;
        align-items: center;
    } */
    @media screen and (max-width: 768px) {
        .modalDeskTop {
            display: none;
        }
        .modalMobile {
            display: flex;
        }
    }
`;

export default ItemCaroselStyle;
