import React, { useEffect } from 'react';
import IntineraryEventStyle from './styled';
import FlightCardComponent from 'src/components/flightCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TransferCard from 'src/components/tranferCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import HotelCardComponent from 'src/components/hotelCard';
import GolfsCardComponent from 'src/components/golfsCard';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';
import TourEvenComponent from 'src/components/tourComponent';
interface intineraryProps {
    dataItinerary?: any;
    dateNumber?: any;
    customProp?: any;
    tourName?: any;
    lastItem?: any;
    AvatarAgent?: any;
    totalItem?: any;
    refetchApiItinarery?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isloading?: boolean;
}

const ItineraryInDetailEventComponent: React.FC<intineraryProps> = ({
    dataItinerary,
    dateNumber,
    customProp,
    tourName,
    lastItem,
    AvatarAgent,
    totalItem,
    refetchApiItinarery,
    roleBtnEditOnHeader,
    quoteTitle,
    isloading,
}) => {
    const words = tourName?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    const date = dataItinerary?.date || null;
    const accommodations = dataItinerary?.accommodations || null;
    const flight = dataItinerary?.flights || [];
    const carHire = dataItinerary?.carHire || null;
    const transfers = dataItinerary?.transfers || null;
    const golf = dataItinerary?.golfs || null;
    const tours = dataItinerary?.tours || null;
    const totalItemData = totalItem || 0;

    const filteredArrayAirport = transfers.filter((item: any) =>
        item.carType.includes('Airport'),
    );
    const filteredArrayGolf = transfers.filter((item: any) =>
        item.carType.includes('Golf'),
    );
    const filteredArrayHotel = transfers.filter((item: any) =>
        item.carType.includes('Hotel'),
    );
    const filteredArrayResort = transfers.filter((item: any) =>
        item.carType.includes('Reort'),
    );
    const filteredArrayOthers = transfers.filter(
        (item: any) =>
            !item.carType.includes('Airport') &&
            !item.carType.includes('Golf') &&
            !item.carType.includes('Hotel') &&
            !item.carType.includes('Resort'),
    );

    const outboundArrayGolf = filteredArrayGolf.filter(
        (item: any) => item.direction === 'Outbound',
    );

    const inboundArrayGolf = filteredArrayGolf.filter(
        (item: any) => item.direction === 'Inbound',
    );

    const defaultImage = customProp?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;

    const getMainImage = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMainImage(firstTwoWords);
    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );
    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : '';

    useEffect(() => {
        const handleScrollEvent = (event: any) => {
            const id = event.detail.id;
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        };
        window.addEventListener('scrollToSectionId', handleScrollEvent);
        return () => {
            window.removeEventListener('scrollToSectionId', handleScrollEvent);
        };
    }, []);

    return (
        <IntineraryEventStyle id={dateNumber}>
            <div className="titleDay1">
                Day {dateNumber}:{' '}
                {moment(date, 'YYYY-MM-D').format('dddd Do MMMM YYYY')}
            </div>

            {flight?.length > 0 ||
            carHire ||
            transfers?.length > 0 ||
            accommodations?.length > 0 ||
            golf?.length > 0 ||
            tours?.length > 0 ? (
                <div
                    style={{
                        display:
                            lastItem && totalItemData > 1 ? 'flex' : 'flex',
                        flexDirection:
                            lastItem && totalItemData > 1
                                ? 'column-reverse'
                                : 'column',
                    }}
                >
                    {flight && (
                        <div>
                            {flight?.map((item: any, index: any) => {
                                return (
                                    <div key={index}>
                                        <FlightCardComponent
                                            dataFlight={item}
                                            avatarAgent={AvatarAgent}
                                            refetchApi={refetchApiItinarery}
                                            roleBtnEditOnHeader={
                                                roleBtnEditOnHeader
                                            }
                                            quoteTitle={quoteTitle}
                                            isloading={isloading}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {carHire && (
                        <div>
                            <CarHideCardComponent
                                dataCarHide={carHire}
                                lastItem={lastItem}
                                avatarAgent={AvatarAgent}
                                refetchApi={refetchApiItinarery}
                                roleBtnEditOnHeader={roleBtnEditOnHeader}
                                quoteTitle={quoteTitle}
                                isloading={isloading}
                            />
                        </div>
                    )}

                    {filteredArrayAirport?.length > 0 && (
                        <div>
                            {filteredArrayAirport?.map(
                                (item: any, index: any) => {
                                    return (
                                        <TransferCard
                                            dataTransfer={item}
                                            key={index}
                                            avatarAgent={AvatarAgent}
                                            refetchApi={refetchApiItinarery}
                                            roleBtnEditOnHeader={
                                                roleBtnEditOnHeader
                                            }
                                            quoteTitle={quoteTitle}
                                            isLoading={isloading}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}

                    {accommodations?.length > 0 && (
                        <div>
                            {accommodations?.map((item: any, index: any) => {
                                return (
                                    <HotelCardComponent
                                        dataHotel={item}
                                        key={index}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                        isLoading={isloading}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {filteredArrayHotel?.length > 0 && (
                        <div>
                            {filteredArrayHotel?.map(
                                (item: any, index: any) => {
                                    return (
                                        <TransferCard
                                            dataTransfer={item}
                                            key={index}
                                            avatarAgent={AvatarAgent}
                                            refetchApi={refetchApiItinarery}
                                            roleBtnEditOnHeader={
                                                roleBtnEditOnHeader
                                            }
                                            quoteTitle={quoteTitle}
                                            isLoading={isloading}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}
                    {filteredArrayResort?.length > 0 && (
                        <div>
                            {filteredArrayResort?.map(
                                (item: any, index: any) => {
                                    return (
                                        <TransferCard
                                            dataTransfer={item}
                                            key={index}
                                            avatarAgent={AvatarAgent}
                                            refetchApi={refetchApiItinarery}
                                            roleBtnEditOnHeader={
                                                roleBtnEditOnHeader
                                            }
                                            quoteTitle={quoteTitle}
                                            isLoading={isloading}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}

                    {outboundArrayGolf?.length > 0 && (
                        <div>
                            {outboundArrayGolf?.map((item: any, index: any) => {
                                return (
                                    <TransferCard
                                        dataTransfer={item}
                                        key={index}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                        isLoading={isloading}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {golf?.length > 0 && (
                        <div>
                            {golf?.map((item: any, index: any) => {
                                return (
                                    <GolfsCardComponent
                                        dataGolf={item}
                                        key={index}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                    />
                                );
                            })}
                        </div>
                    )}

                    {inboundArrayGolf?.length > 0 && (
                        <div>
                            {inboundArrayGolf?.map((item: any, index: any) => {
                                return (
                                    <TransferCard
                                        dataTransfer={item}
                                        key={index}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                        isLoading={isloading}
                                    />
                                );
                            })}
                        </div>
                    )}
                    <div>
                        {filteredArrayOthers?.length > 0 && (
                            <div>
                                {filteredArrayOthers?.map(
                                    (item: any, index: any) => {
                                        return (
                                            <TransferCard
                                                dataTransfer={item}
                                                key={index}
                                                avatarAgent={AvatarAgent}
                                                refetchApi={refetchApiItinarery}
                                                roleBtnEditOnHeader={
                                                    roleBtnEditOnHeader
                                                }
                                                quoteTitle={quoteTitle}
                                                isLoading={isloading}
                                            />
                                        );
                                    },
                                )}
                            </div>
                        )}
                        {tours?.length > 0 && (
                            <div className="">
                                {tours?.map((item: any, index: any) => {
                                    const tourDayComments =
                                        item?.tourDayComments || [];
                                    return (
                                        <div key={index}>
                                            {imageUrlTourTheOpen &&
                                                item?.type === 'Tour' && (
                                                    <div className="mainImage">
                                                        <img
                                                            alt=""
                                                            className="imageEvent"
                                                            src={
                                                                imageUrlTourTheOpen
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            <TourEvenComponent
                                                tourData={item}
                                                tourDayComments={
                                                    tourDayComments
                                                }
                                                avatarAgent={AvatarAgent}
                                                refetchApi={refetchApiItinarery}
                                                roleBtnEditOnHeader={
                                                    roleBtnEditOnHeader
                                                }
                                                quoteTitle={quoteTitle}
                                                isLoading={isloading}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="freeDay">
                        <div className="titleFreeDay">Day at your leisure</div>
                        <FontAwesomeIcon className="iconCup" icon={faSun} />
                    </div>
                </div>
            )}
        </IntineraryEventStyle>
    );
};

export default ItineraryInDetailEventComponent;
