import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import TourCardStyle from './style';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';
import { marked } from 'marked';
import parse from 'html-react-parser';

interface TourEventProps {
    tourData?: any;
    tourDayComments?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: any;
}

const TourEvenComponent: React.FC<TourEventProps> = ({
    tourData,
    tourDayComments,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
}) => {
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };
    const agentComments = tourData?.agentComments || [];
    const itemId = tourData?.id;
    return (
        <TourCardStyle mode={isRole}>
            <div>
                {tourData?.type === 'Event' && (
                    <div className="nameEventTicket">
                        <div className="headerTicket">
                            <div className="titleTicket">
                                {tourData?.description}
                            </div>

                            {type === 'Event' && (
                                <FontAwesomeIcon
                                    className="iconCup"
                                    icon={faTrophy}
                                />
                            )}
                        </div>
                        <div className="bodyTicket">
                            <div>
                                {tourDayComments.map(
                                    (item: any, index: any) => {
                                        const formattedText = item?.text
                                            ?.split('\r\n')
                                            .map((line: any) => `• ${line}`)
                                            .join('<br>');
                                        return (
                                            <div
                                                className="contentEvent"
                                                key={index}
                                            >
                                                {parse(formattedText)}
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {tourData?.type === 'Event' && (
                    <div
                        className="iconAddComment"
                        onClick={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                    >
                        <img
                            alt=""
                            className="iconAdd"
                            src={icons.addComment}
                        />
                    </div>
                )}
            </div>
            {(agentComments?.length > 0 || showEditAgentComment) &&
                agentComments?.map((item: any, index: any) => {
                    return (
                        <AgentCommentComponent
                            key={index}
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="EventTour"
                            dataAgentComment={item}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                        />
                    );
                })}
            {agentComments?.length === 0 && showEditAgentComment && (
                <AgentCommentComponent
                    showEdit={showEditAgentComment}
                    itemId={itemId}
                    typeOfComent="EventTour"
                    dataAgentComment={null}
                    avatarAgent={avatarAgent}
                    changeShowEdit={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                    refetch={refetchApi}
                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                    quoteTitle={quoteTitle}
                    isLoading={isLoading}
                />
            )}
        </TourCardStyle>
    );
};

export default TourEvenComponent;
