import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import QuoteDetailPageStyle from './style';
import MobileQouteDetailPage from './mobile';
import DeskTopQuoteDetailPage from './desktop';
import { getDetailQuote } from 'src/api/apiGetDetailQuote';
import { getQuoteDetailJson } from 'src/api/apiGetQuoteDetailJson';
import { getImageApi } from 'src/api/apiGetImage';
import { useDispatch, useSelector } from 'react-redux';
import { updateContenfullSuccess } from 'src/redux/actions/contenfullAction';
import { quoteIdSelector } from 'src/redux/selectors/quoteIdSelecter';
import useQueryApi from 'src/hooks/useApiQuery';
import { getQuoteDetailVersion } from 'src/api/apiGetQuoteDetailVersion';

const QuoteDetailPage = () => {
    const [data, setData] = useState<any>();
    const [mainImage, setMainImage] = useState<any>();
    const [dataJson, setDataJson] = useState<any>();
    const location: any = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';
    const type = queryParams.get('type');

    const { data: dataVersion } = useQueryApi(
        ['id', id],
        () => getQuoteDetailVersion(id),
        {
            enabled: !!id,
        },
    );

    const dataVer = (dataVersion as { data: any })?.data;
    const contenfullDataVer = dataVer?.contentful || null;
    const introductionDataVer = dataVer?.introduction || null;
    const defaultImageIdVer = dataVer?.contentful?.defaultImage?.sys?.id;

    const { data: dataImageVer } = useQueryApi(
        ['defaultImageIdVer', defaultImageIdVer],
        () => getImageApi(defaultImageIdVer),
        {
            enabled: !!defaultImageIdVer,
        },
    );
    const dataImageVerParserString =
        `http:${(dataImageVer as { fields: any })?.fields?.file?.url}` || '';

    const fetchData = async () => {
        try {
            const [response, responseDataJson] = await Promise.all([
                getDetailQuote(),
                id ? getQuoteDetailJson(id) : Promise.resolve(null),
            ]);

            if (responseDataJson) {
                setDataJson(responseDataJson);
            } else {
                setDataJson(null);
            }

            setData(response?.fields);
            dispatch(updateContenfullSuccess(response?.fields));

            const defaultImageId = response?.fields?.defaultImage?.sys?.id;
            if (defaultImageId) {
                try {
                    const imageResponse = await getImageApi(defaultImageId);
                    setMainImage(imageResponse);
                } catch (err) {
                    setMainImage(null);
                }
            }
        } catch (err) {
            setData(null);
        }
    };

    useEffect(() => {
        if(type){
            fetchData();
        }
    }, []);
    const imageIntroDuction = `http:${mainImage?.fields?.file?.url}`;
    return (
        <Router>
            <QuoteDetailPageStyle>
                <div className="deskTopView">
                    <DeskTopQuoteDetailPage
                        Imange={
                            !type ? dataImageVerParserString : imageIntroDuction
                        }
                        dataContenFull={!type ? contenfullDataVer : data}
                        dataJsonChild={!type ? introductionDataVer : dataJson}
                        onUpdate={fetchData}
                    />
                </div>
                <div className="mobileView">
                    <MobileQouteDetailPage
                        Imange={
                            !type ? dataImageVerParserString : imageIntroDuction
                        }
                        dataContenFull={!type ? contenfullDataVer : data}
                        dataJsonChild={!type ? introductionDataVer : dataJson}
                    />
                </div>
            </QuoteDetailPageStyle>
        </Router>
    );
};

export default React.memo(QuoteDetailPage);
