import React, { useEffect, useState } from 'react';
import ModalConfirmStyle from './styled';
import { Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import { newQuoteIntroSelector } from 'src/redux/selectors/editQuoteIntroSelecter';
import { newQuoteTitleSelector } from 'src/redux/selectors/editQuoteTitleSelecter';
import { apiUpdateQuote } from 'src/api/apiUpdateQuote';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EventBusService } from 'src/hooks/EventBus';
import { updatePriceSelector } from 'src/redux/selectors/updatePriceSelector';
import { contenfullSelector } from 'src/redux/selectors/contenfullSelector';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { saveVersionApi } from 'src/api/apiSaveVersion';

interface ModalConfirmProp {
    quoteTitle?: string;
    close?: () => void;
    roleBtn?: (state: boolean) => void;
    modeView?: string;
    updateData?: () => void;
}

const ModalConfirmComponent: React.FC<ModalConfirmProp> = ({
    quoteTitle,
    close,
    roleBtn,
    modeView,
    updateData,
}) => {
    const [changeView, setChangeView] = useState<string>('save');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';
    const [dataParam, setDataParam] = useState<any>({
        title: '',
        intro: '',
        price: [],
    });
    const [value, setValue] = useState('');

    const newQuoteTitle = useSelector(newQuoteTitleSelector);
    const newQuoteIntro = useSelector(newQuoteIntroSelector);
    const newPrice = useSelector(updatePriceSelector);
    const contenfull = useSelector(contenfullSelector);

    const eventUpdateQuote = async () => {
        try {
            if (id) {
                const response = await apiUpdateQuote(id, dataParam);
                if (response) {
                    close?.();
                    toast.success('Your updates have been saved.');
                    roleBtn?.(false);
                    EventBusService.emit('UPDATE_DATA', {
                        data: 'New data from Component A',
                    });
                    updateData?.();
                }
            }
        } catch (Error) {
            toast.error(
                'An error occurred while saving your changes. Please try again later.',
            );
            close?.();
            roleBtn?.(false);
        }
    };

    const changeText = (e: any) => {
        const inputText = e.target.value;
        setValue(inputText);
    };
    const { mutate } = useMutationApi<{ param: any }, any>(
        ({ param }) => saveVersionApi(param),
        {
            onSuccess: (response) => {
                toast.success(`Email sent successfully!`);
                console.log('response', response);
                close?.();
            },
            onError: (error) => {
                toast.error(`Failed to send email. Please try again.`);
                console.log('error', error);
                close?.();
            },
        },
    );

    const saveVersionEvent = () => {
        const data = {
            quoteRef: id,
            detail: value,
            contentful: contenfull,
        };
        mutate({ param: data });
    };

    useEffect(() => {
        const result = newPrice.reduce((acc, item) => {
            if (
                item &&
                item.nameGroup &&
                item.price !== undefined &&
                !isNaN(item.price)
            ) {
                acc[item.nameGroup] = `${item.price}`;
            }
            return acc;
        }, {});

        setDataParam({
            title: newQuoteTitle,
            intro: newQuoteIntro,
            priceGroups: result,
        });
        if (modeView) {
            setChangeView(modeView);
        }
    }, [
        quoteTitle,
        newQuoteIntro,
        newQuoteTitle,
        changeView,
        modeView,
        newPrice,
    ]);

    return (
        <ModalConfirmStyle>
            {changeView === 'save' ? (
                <div>
                    <div className="contentModal">
                        Are you sure to save the quote{' '}
                        <span className="quoteTitle">{quoteTitle}</span>
                    </div>
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={close}
                            ghost
                        >
                            Back to Edit
                        </Button>
                        <Button
                            type="primary"
                            className="btnSend"
                            onClick={eventUpdateQuote}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="contentModal">
                        Are you sure to save the quote{' '}
                        <span className="quoteTitle">{quoteTitle}</span> -
                        Version
                        <span className="quoteTitle"> X?</span> to{' '}
                        <span className="quoteTitle">Client Name?</span>
                    </div>
                    <div className="groupInput">
                        <div>Version details</div>
                        <Input
                            className="inputNew"
                            placeholder="Enter your content here"
                            onChange={(e) => changeText(e)}
                        />
                    </div>
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={() => {
                                close?.();
                            }}
                            ghost
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="btnSend"
                            onClick={saveVersionEvent}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            )}
        </ModalConfirmStyle>
    );
};

export default ModalConfirmComponent;
