import React, { useState } from 'react';
import icons from 'src/assets/icon';
import ModalComponent from 'src/components/modalCustom';
import ItemCaroselStyle from './style';

interface ItemCaroselProps {
    dataItemCarosel?: any;
    key?: any;
}

const ItemCarosel: React.FC<ItemCaroselProps> = ({ dataItemCarosel, key }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const maxLength = 78;
    const shortDescription =
        dataItemCarosel?.description?.length > maxLength
            ? dataItemCarosel?.description?.substring(0, maxLength) + '...'
            : dataItemCarosel?.description;
    return (
        <div className="viewItemCarousel" key={key}>
            <div className="titleItemCarousel">
                <div className="pointCarousel">{dataItemCarosel?.point}</div>
                <div className="commentCarousel">
                    {dataItemCarosel?.comment}
                </div>
            </div>
            <div className="discriptionCarousel">
                {shortDescription} <img alt="" src={icons.qouteRight} />
            </div>
            <div className="viewBtnSeeMore">
                {dataItemCarosel?.description?.length > maxLength && (
                    <div
                        className="btnSeeMore"
                        onClick={() => setIsModalVisible(true)}
                    >
                        See more
                    </div>
                )}
                <div className="SignCarousel">{dataItemCarosel?.sign}</div>
            </div>
            <ModalComponent
                width={500}          
                visible={isModalVisible}
                onClose={handleClose}
                // title="Confirmation"
                content={
                    <ItemCaroselStyle>
                        <div className="titleItemCarouselMoldal">
                            <div className="pointCarouselModal">
                                {dataItemCarosel?.point}
                            </div>
                            <div className="commentCarouselModal">
                                {dataItemCarosel?.comment}
                            </div>
                        </div>
                        <div className="discriptionCarouselModal">
                            {dataItemCarosel?.description} "
                        </div>
                        <div className="viewBtnSeeMore">
                            <div className="SignCarouselModal">
                                {dataItemCarosel?.sign}
                            </div>
                        </div>
                    </ItemCaroselStyle>
                }
            />
        </div>
    );
};

export default ItemCarosel;
