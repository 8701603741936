import styled from '@emotion/styled';
interface AgentComentProps {
    mode?: any;
}

const AgentCommentStyle = styled('div')<AgentComentProps>`
    .reviewSeller {
        width: 100%;
        justify-content: space-between;
        background-color: #1D3254 !important;
        color: #FFFFFF !important;
    }
    .viewDataComment {
        width: 80%;
        word-wrap: break-word;
        text-align: center;
    }
    .avatarSeller {
        width: auto !important;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .avatar{
        background-color: #FFFFFF;
        border-radius: 70px;
        width: 87px;
        height: 87px;
    }
    .viewInputEdit {
        width: 80%;
    }
    .reviewSellerAgentComent {
        display: flex;
        flex-direction: column;
        padding: 34px 50px 34px 30px;
        /* align-items: center; */
        border-radius: 4px;
        background: #1D3254;
        margin-bottom: 20px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .reviewSellerInput {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
    }
    .btnEditComment {
        display: flex;
        width: 80%;
        justify-content: space-between;
    }
    .btnSave {
        color:#FFFFFF;
        margin-right: 16px;
        font-family: 'Lato';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-decoration-line: underline;
        cursor: ${(props) => (props.mode !== 0 ? 'pointer' : 'not-allowed')};
    }
    .btnSave:hover {
        transform: scale(1.05);
    }
    .btnDelete {
        color:#FFFFFF;
        font-family: 'Lato';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-decoration-line: underline;
        cursor: pointer;
    }
    .btnDelete:hover {
        transform: scale(1.05);
    }
    .backgroundAvatar {
        width: 93px;
        height: 95px;
        display: flex;
        background-color: #12b897;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
    .avatarReals {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
        border-radius: 45px;
    }
    .btnLeft {
        display: flex;
    }
    .areaMaxlenght {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .viewMaxLenght {
        padding: 8px 10px;
        align-items: center;
        background: rgbva(18, 184, 151, 0.1);
        color: #FFFFFF;
        text-align: center;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 400;
        border-top: 1px solid #FFFFFF;
        border-right: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;
    }
    .textAreaComment {
        border-radius: 0px;
        border: 1px solid #12b897;
    }
    .textAreaComment:hover {
        border-radius: 0px;
        border: 1px solid #12b897;
    }
    @media screen and (max-width: 768px) {
        .reviewSeller {
            display: flex;
            flex-direction: column;
            padding: 20px !important;
            gap: 24px !important;
        }
        .viewDataComment {
            width: 100%;
            word-wrap: break-word;
            text-align: left;
            font-family: 'Lato';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.16px;
        }
        .avatarSeller {
            width: 100% !important;
            align-items: flex-end;
            background-color: #1D3254 !important;
        }
    }
`;

export default AgentCommentStyle;
