import React from 'react';
import ModalTouristDetinationStyle from './style';
import images from 'src/assets/image';

interface ModalTourDestination{
    image?:string,
    discription?: string
}
const ModalTouristDetination: React.FC<ModalTourDestination> = ({image,discription}) => {
    return (
        <ModalTouristDetinationStyle>
            <img alt="" className="imgLocation" src={image?image:images.hotelImgFull} />
            <div className='content'>
                {discription}
            </div>
        </ModalTouristDetinationStyle>
    );
};

export default ModalTouristDetination;
