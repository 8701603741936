import React, { useState } from 'react';
import FlightCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';

interface flightCardProps {
    dataFlight?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isloading?: boolean;
}

const FlightCardComponent: React.FC<flightCardProps> = ({
    dataFlight,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isloading,
}) => {
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const nameFlight = dataFlight?.name || ' ';
    const departureFlight = dataFlight?.departure || null;
    const departureTimeFlight = dataFlight?.departure?.date || '';
    const arrivalFlight = dataFlight?.arrival || null;
    const arrivalTimeFlight = dataFlight?.arrival?.date || '';
    const airline = dataFlight?.airline || null;
    const numOfPassenger = dataFlight?.passenger || 0;
    // const extra = dataFlight?.extra || null;
    const baggage = dataFlight?.baggage || null;
    const classAirline = dataFlight?.class || null;
    const agentComments = dataFlight?.agentComments || [];
    const itemId = dataFlight?.id;
    const extras = dataFlight?.extras || null;
    const formatDepartureDay = new Date(departureTimeFlight);
    const formatArrivalDay = new Date(arrivalTimeFlight);
    const departureDay = formatDepartureDay?.getUTCDate();
    const arrivalDay = formatArrivalDay?.getUTCDate();

    const isArrivalNextDay = arrivalDay > departureDay;

    return (
        <FlightCardStyle mode={isRole}>
            <div>
                <div className="traditionView">
                    <div className="viewDetailPlanFlight">
                        <div className="headerCard">
                            <div className="titleFightPlan">{nameFlight}</div>
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faPlane}
                            />
                        </div>
                        <div>
                            <div className="detailPlanflight">
                                <FontAwesomeIcon
                                    className="iconTransportationDetail"
                                    icon={faPlaneDeparture}
                                />
                                <div>
                                    <div className="locationName">
                                        {departureFlight?.name} (
                                        {departureFlight?.code})
                                    </div>
                                    <div className="textTimeFont">
                                        Departing{' '}
                                        {moment
                                            .utc(departureTimeFlight)
                                            .format('HH:mm') === '00:00'
                                            ? 'time TBC'
                                            : moment
                                                  .utc(departureTimeFlight)
                                                  .format('HH:mm')}
                                    </div>
                                </div>
                            </div>
                            <div className="detailPlanflight">
                                <FontAwesomeIcon
                                    className="iconTransportationDetail"
                                    icon={faPlaneArrival}
                                />
                                <div>
                                    <div className="locationName">
                                        {arrivalFlight?.name} (
                                        {arrivalFlight?.code}){' '}
                                    </div>
                                    <div className="textTimeFont">
                                        Arriving{' '}
                                        {moment
                                            .utc(arrivalTimeFlight)
                                            .format('HH:mm') === '00:00'
                                            ? 'time TBC'
                                            : moment
                                                  .utc(arrivalTimeFlight)
                                                  .format('HH:mm')}{' '}
                                        {isArrivalNextDay && '(Next day)'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="viewFlightinformation">
                            <div className="textFont">
                                Airline: {airline?.number} {airline?.operatedBy}
                            </div>
                            {classAirline && (
                                <div className="textFont">
                                    Class: {classAirline}
                                </div>
                            )}
                            <div className="textFont">
                                Passenger{Number(numOfPassenger) > 1 ? 's' : ''}
                                : {numOfPassenger}
                            </div>
                            <div className="textFont">
                                <div>Hand luggage </div>
                                {baggage && baggage !== '0' ? (
                                    <div>{`Baggage Allowance: ${baggage}`}</div>
                                ) : (
                                    ''
                                )}
                                {extras?.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            {item?.description} x{' '}
                                            {item?.quantity}
                                        </div>
                                    );
                                })}
                                {/*
                                {extra.luggage ? (
                                    <div>{`1 piece of luggage x ${extra?.luggage}`}</div>
                                ) : (
                                    ''
                                )}{' '}
                                {extra.golfBags ? (
                                    <div>{` Golf bags x ${extra?.golfBags}`}</div>
                                ) : (
                                    ''
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="iconAddComment"
                    onClick={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                >
                    <img alt="" className="iconAdd" src={icons.addComment} />
                </div>
            </div>
            {(agentComments?.length > 0 || showEditAgentComment) &&
                agentComments?.map((item: any, index: any) => {
                    return (
                        <AgentCommentComponent
                            key={index}
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Flight"
                            dataAgentComment={item}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isloading}
                        />
                    );
                })}
            {agentComments?.length === 0 && showEditAgentComment && (
                <AgentCommentComponent
                    showEdit={showEditAgentComment}
                    itemId={itemId}
                    typeOfComent="Flight"
                    dataAgentComment={null}
                    avatarAgent={avatarAgent}
                    changeShowEdit={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                    refetch={refetchApi}
                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                    quoteTitle={quoteTitle}
                    isLoading={isloading}
                />
            )}
        </FlightCardStyle>
    );
};

export default FlightCardComponent;
