import React, { useState } from 'react';
import HotelCardStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import AgentCommentComponent from '../agentComment';

interface hotelCardProps {
    dataHotel?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
}

const HotelCardComponent: React.FC<hotelCardProps> = ({
    dataHotel,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);

    const isRole = useSelector(roleSelector);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const hotelName = dataHotel?.name || '';
    const hotelAddress = dataHotel?.address || '';
    const hotelStartDate = dataHotel?.startDate || '';
    const hotelRoomType = dataHotel?.roomType || null;
    const agentComments = dataHotel?.agentComments || [];
    const itemId = dataHotel?.id;
    const hotelExtra = dataHotel?.extra || null;

    return (
        <HotelCardStyle mode={isRole}>
            <div className="desktopView">
                <div>
                    <div className="hotelAndGoflView">
                        <div className="viewInforHotel">
                            <img
                                alt=""
                                className="imgHotelOrGofl"
                                src={images.hotelImage}
                            />
                            <div>
                                <div className="titleItem">
                                    Stay at {hotelName}{' '}
                                </div>
                                <div className="addressHotelOrGolf">
                                    {hotelAddress?.county}
                                </div>
                                <div className="contentInfor">
                                    {dataHotel?.duration} Night
                                    {Number(dataHotel?.duration) !== 1 &&
                                        's'}{' '}
                                    on{' '}
                                    {moment(hotelStartDate).format(
                                        'ddd Do MMMM YYYY',
                                    )}
                                </div>
                                {hotelRoomType?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className="discriptHotel"
                                            key={index}
                                        >
                                            <FontAwesomeIcon
                                                className="iconDetail"
                                                icon={faBed}
                                            />{' '}
                                            {item?.numOfRooms} x{' '}
                                            {item?.nameRoomType} for{' '}
                                            {item?.numOfPassenger}{' '}
                                            {item?.numOfPassenger > 1
                                                ? 'guests'
                                                : 'guest'}{' '}
                                            {item?.singleOccupancy &&
                                                '(Single Occupancy)'}
                                            {`${item?.boardBasis && ','} ${
                                                item?.boardBasis
                                            }`}
                                            {item?.extra?.nbrCode && ','}{' '}
                                            {item?.extra?.nbrCode}{' '}
                                            {item?.extra[0]?.detail?.length > 0
                                                ? item?.extra[0]?.detail[0]
                                                      ?.text
                                                : ''}{' '}
                                            {`${
                                                item?.extra?.length > 0
                                                    ? 'x'
                                                    : ''
                                            }`}{' '}
                                            {`${
                                                item?.extra?.length > 0
                                                    ? item?.extra[0]?.quantity
                                                    : ''
                                            }`}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="iconAndBtnItem">
                            <img
                                alt=""
                                className="iconHotelorGolf"
                                src={icons.hotelIcon}
                            />
                            <div
                                className="btnSeeDetail"
                                onClick={() => showModal()}
                            >
                                See details
                            </div>
                        </div>
                    </div>
                    <div
                        className="iconAddComment"
                        onClick={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                    >
                        <img
                            alt=""
                            className="iconAdd"
                            src={icons.addComment}
                        />
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Accommodation"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Accommodation"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </div>
            <div className="mobileView">
                <div className="hotelAndGoflViewMobile">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={images.hotelImage}
                    />
                    <div className="bodyViewMobileCard">
                        <div className="headerMobile">
                            <div className="titleItem">
                                Stay at {hotelName}{' '}
                            </div>
                            <img
                                alt=""
                                className="iconHotelorGolf"
                                src={icons.hotelIcon}
                            />
                        </div>
                        <div className="addressHotelOrGolf">
                            {hotelAddress?.county}
                        </div>
                        <div className="contentInfor">
                            {dataHotel?.duration} Night
                            {Number(dataHotel?.duration) !== 1 && 's'} on{' '}
                            {moment(hotelStartDate).format('ddd Do MMMM YYYY')}
                        </div>
                        {hotelRoomType?.map((item: any, index: any) => {
                            const extra = hotelExtra[index] || null;
                            const detailExtra = extra?.detail[0] || null;
                            return (
                                <div
                                    className="discriptHotel mobileDiscription"
                                    key={index}
                                >
                                    <FontAwesomeIcon
                                        className="iconDetail"
                                        icon={faBed}
                                    />{' '}
                                    <div className="contentItemMobile">
                                        {item?.numOfRooms} x{' '}
                                        {item?.nameRoomType} for{' '}
                                        {item?.numOfPassenger}{' '}
                                        {item?.numOfPassenger > 1
                                            ? 'guests'
                                            : 'guest'}{' '}
                                        {item?.singleOccupancy &&
                                            '(Single Occupancy)'}
                                        {`${item?.boardBasis && ','} ${
                                            item?.boardBasis
                                        }`}
                                        {item?.extra?.nbrCode && ','}{' '}
                                        {item?.extra?.nbrCode}{' '}
                                        {item?.extra[0]?.detail?.length > 0
                                            ? item?.extra[0]?.detail[0]?.text
                                            : ''}{' '}
                                        {`${
                                            item?.extra?.length > 0 ? 'x' : ''
                                        }`}{' '}
                                        {`${
                                            item?.extra?.length > 0
                                                ? item?.extra[0]?.quantity
                                                : ''
                                        }`}
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className="btnSeeDetail"
                            onClick={() => showModal()}
                        >
                            See details
                        </div>
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Accommodation"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Accommodation"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                        />
                    )}
                </div>
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={`Stay at ${hotelName}`}
                content={<ModalTouristDetination />}
            />
        </HotelCardStyle>
    );
};

export default HotelCardComponent;
