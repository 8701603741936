import styled from '@emotion/styled';

const ModalConfirmStyle = styled('div')`
    .contentModal {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
    }
    .quoteTitle {
        color: #069;
        font-size: 16px;
        font-weight: 400;
    }
    .btnSend {
        border: 2px solid #069;
        background: #069;
        margin-left: 16px;
    }
    .btnSend:hover {
        border: 2px solid #069;
        background: #069 !important;
    }
    .btnEdit {
        border-radius: 6px;
        border: 2px solid #069;
        background: #fff;
        color: #069;
    }
    .btnEdit:hover {
        border-radius: 6px;
        border: 2px solid #069 !important;
        background: #fff !important;
        color: #069 !important;
    }
    .groupBtn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }
    .groupInput {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .inputNew {
        height: 47px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
        background: #fff;
    }
`;

export default ModalConfirmStyle;
