import styled from '@emotion/styled';

const LinkScrollItineraryStyle = styled('div')`
    background-color: #ffffff;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 17px 20px 17px 20px;
    margin-bottom: 20px;
    .header {
        color: #000;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: 700;
    }
    .body {
        margin-top: 15px;
    }
    .link {
        color: #1a91ff;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
        margin-bottom: 10px;
    }
`;

export default LinkScrollItineraryStyle;
