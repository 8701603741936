import { call, put, takeLatest } from 'redux-saga/effects';
import { authExited, LOGIN } from '../actions/authenAction';
import { loginApi } from 'src/api/apiLogin';
import { toast } from 'react-toastify';

function* loginSaga(action: any) {
    try {
        const response: Promise<any> = yield call(loginApi, action.payload);
        const { access_token, refresh_token }: any = response || {};
        if (access_token) {
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);

            yield put(authExited(true));
            action.payload?.history.push('/dashboard');
            toast.success('Login successful');
        } else {
            action.payload?.history.push('/login');
            toast.error('Login false');
        }
    } catch (error: any) {
        action.payload?.history.push('/login');
        toast.error('Reservations user profile not found. Please contact the ACE Team.');
    } finally {
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield takeLatest(LOGIN, loginSaga);
}
