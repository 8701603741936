import React from 'react';
import ImportantInformationStyle, {
    ImportantInformationMainImageStyle,
} from './style';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';

interface BaseNode {
    data: any;
    content?: Node[];
    nodeType: string;
    marks?: any[];
}

interface TextNode extends BaseNode {
    nodeType: 'text';
    value: string;
}
interface ImportantInforProps {
    dataImportanInfor?: any;
    dataJsonProp?: any;
    image?: any;
}

type Node = BaseNode | TextNode;
const ImportantInformationComponent: React.FC<ImportantInforProps> = ({
    dataImportanInfor,
    dataJsonProp,
    image,
}) => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    const checkEvent = dataJsonProp?.isEvent;

    //id image tour event
    const defaultImage = dataImportanInfor?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        dataImportanInfor?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any =
        dataImportanInfor?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any =
        dataImportanInfor?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        dataImportanInfor?.solheimCupImage?.sys?.id || null;

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    const extractValues = (node: Node): string[] => {
        let values: string[] = [];

        if (Array.isArray(node?.content)) {
            node?.content?.forEach((childNode: Node) => {
                if (childNode.nodeType === 'text') {
                    const textNode = childNode as TextNode;
                    values.push(textNode.value);
                } else {
                    values = values.concat(extractValues(childNode));
                }
            });
        }

        return values;
    };

    const valueImportantInfor = extractValues(
        type === 'Event'
            ? dataImportanInfor?.quoteImportantNotesTour
            : dataImportanInfor?.quoteImportantNotesStandard,
    );

    return (
        <>
            <ImportantInformationMainImageStyle>
                {(checkEvent === false || type === 'Standard') && (
                    <div>
                        {!image ? (
                            <div className="viewMainImage">
                                <Skeleton.Image
                                    className="imgGoflTravel"
                                    active={true}
                                    style={{ width: '100%', height: 413 }}
                                />
                            </div>
                        ) : (
                            <div className="viewMainImage">
                                <img
                                    alt=""
                                    className="imgGoflTravel"
                                    src={image}
                                />
                            </div>
                        )}
                    </div>
                )}
                {(checkEvent === true || type === 'Event') && (
                    <div>
                        {!imageUrlTourTheOpen ? (
                            <div className="viewMainImage">
                                <Skeleton.Image
                                    className="imgGoflTravel"
                                    active={true}
                                    style={{ width: '100%', height: 413 }}
                                />
                            </div>
                        ) : (
                            <div className="viewMainImage">
                                <img
                                    alt=""
                                    className="imgGoflTravel"
                                    src={imageUrlTourTheOpen}
                                />
                            </div>
                        )}
                    </div>
                )}
            </ImportantInformationMainImageStyle>
            <ImportantInformationStyle>
                <div className="title">Important Notes</div>
                <div className="viewContent">
                    {dataImportanInfor ? (
                        <ul>
                            {valueImportantInfor
                                .filter((item) => item !== '')
                                .map((item, index) => {
                                    return (
                                        <li key={index} className="content">
                                            {item}
                                        </li>
                                    );
                                })}
                        </ul>
                    ) : (
                        <Skeleton active={true} />
                    )}
                </div>
            </ImportantInformationStyle>
        </>
    );
};

export default ImportantInformationComponent;
