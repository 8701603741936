import {
    faBed,
    faCar,
    faFlag,
    faGolfBallTee,
    faMap,
    faPlane,
    faTaxi,
    faTrophy,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icons from 'src/assets/icon';
import { formatCurrency } from 'src/const/contain';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import GroupSummaryStyle from './style';
import { Input, Popover } from 'antd';
import { updateEditPriceSuccess } from 'src/redux/actions/updatePriceAction';

interface GroupPriceProps {
    item?: any;
    tourName?: any;
    priceChange?: any;
    numOfGroups?: number;
    departureDate?: any;
}

const GroupPrice: React.FC<GroupPriceProps> = ({
    item,
    tourName,
    priceChange,
    numOfGroups,
    departureDate,
}) => {
    const [show, setShow] = useState<boolean>(false);
    const isRole = useSelector(roleSelector);
    const [viewToInput, setChangeViewToInput] = useState<boolean>(false);
    const [inputvalue, setInputvalue] = useState<any>('');
    const inputPriceRef = useRef<HTMLDivElement | null>(null);
    const isOutsideRef = useRef(false);
    const dispatch = useDispatch();
    const [priceChangeState, setPriceChangeState] = useState<any>('');

    const changerViewToInput = () => {
        setChangeViewToInput(!viewToInput);
    };

    const showDetailPrice = () => {
        setShow(!show);
    };
    const handChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setInputvalue(value);
        }
        if (value === '0') {
            setInputvalue('0');
        }
    };

    useEffect(() => {
        const valuePrice = {
            nameGroup: item?.group,
            price: inputvalue,
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputPriceRef.current &&
                !inputPriceRef.current.contains(event.target as Node)
            ) {
                if (!isOutsideRef.current) {
                    dispatch(updateEditPriceSuccess(valuePrice));
                    isOutsideRef.current = true;
                }
            } else {
                isOutsideRef.current = false;
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, inputvalue, item?.group]);

    const price = formatCurrency(item?.items?.price) || 0;
    const newPriceChange = formatCurrency(Number(priceChange));
    const carHire = item?.items?.carHire || [];
    const flight = item?.items?.flight || [];
    const tranfer = item?.items?.transfer || [];
    const accom = item?.items?.accommodations[0] || null;
    const totalPerson = item?.items?.totalPerson || 0;
    const roundOfGolf = item?.items?.golfRounds || 0;
    const startDates = moment(departureDate || 0).format('DD/MM/YYYY');

    useEffect(() => {
        setPriceChangeState(
            Number(newPriceChange) !== 0 ? newPriceChange : price,
        );
        setInputvalue(
            Number(priceChange) !== 0 ? priceChange : item?.items?.price,
        );
    }, [item?.items?.price, newPriceChange, price, priceChange]);

    return (
        <GroupSummaryStyle>
            <div className="viewinforPayment">
                {Number(numOfGroups) > 1 ? (
                    <div className="groupComponent">
                        <div className="nameGroups">{item?.group}</div>
                        <div className="title">(price per person)</div>
                    </div>
                ) : (
                    <div className="groupComponent">
                        <div className="nameGroups">Price per person</div>
                    </div>
                )}
                {isRole === 'Edit mode' ? (
                    <div>
                        {!viewToInput ? (
                            <Popover
                                placement="rightTop"
                                content={'Click to edit'}
                            >
                                <div
                                    className="numGroupsEdit"
                                    onClick={changerViewToInput}
                                >
                                    £ {priceChangeState}
                                </div>
                            </Popover>
                        ) : (
                            <div className="numGroupsEdit" ref={inputPriceRef}>
                                <Input
                                    className="inputPriceNum"
                                    variant="borderless"
                                    onChange={handChangePrice}
                                    value={inputvalue}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="numGroups">£{priceChangeState}</div>
                )}
                <img
                    alt=""
                    className="iconPlus"
                    src={show ? icons.minusIcon : icons.plusIcon}
                    onClick={showDetailPrice}
                />
            </div>
            {show && (
                <div className="inforGroup">
                    {tourName && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTrophy}
                            />
                            <div className="titleDetailInfor">{tourName}</div>
                        </div>
                    )}
                    <div className="detailInfor">
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faUserGroup}
                        />
                        <div className="titleDetailInfor">
                            {totalPerson}{' '}
                            {Number(totalPerson) === 1 && 'person'}{' '}
                            {Number(totalPerson) > 1 && 'people'} arriving on{' '}
                            {startDates}
                        </div>
                    </div>
                    {accom && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faMap}
                            />
                            <div className="titleDetailInfor">
                                Staying at {accom?.name}, {accom?.roomType}
                                {`${
                                    accom?.singleOccupancy
                                        ? ' (Single Occupancy)'
                                        : ''
                                }`}
                            </div>
                        </div>
                    )}
                    {accom?.duration && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faBed}
                            />
                            <div className="titleDetailInfor">
                                {accom?.duration}{' '}
                                {Number(accom?.duration) > 1
                                    ? 'Nights'
                                    : 'Night'}
                                , {accom?.boardBasis}
                            </div>
                        </div>
                    )}
                    {roundOfGolf > 0 && (
                        <div className="detailInfor">
                            {tourName ? (
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faGolfBallTee}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faFlag}
                                />
                            )}
                            <div className="titleDetailInfor">
                                {' '}
                                {roundOfGolf}{' '}
                                {roundOfGolf > 1 ? 'Rounds' : 'Round'} of Golf
                            </div>
                        </div>
                    )}
                    {flight.length > 0 && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faPlane}
                            />
                            <div className="titleDetailInfor">
                                Flights as detailed
                            </div>
                        </div>
                    )}
                    {carHire.length > 0 && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faCar}
                            />
                            <div className="titleDetailInfor">
                                Car hire as detailed
                            </div>
                        </div>
                    )}
                    {tranfer.length > 0 && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTaxi}
                            />
                            <div className="titleDetailInfor">
                                Transfers as detailed
                            </div>
                        </div>
                    )}
                </div>
            )}
        </GroupSummaryStyle>
    );
};

export default GroupPrice;
