import styled from '@emotion/styled';

const GroupSummaryStyle = styled('div')`
    .numGroupsEdit {
        font-size: 16px;
        font-weight: 900;
        display: inline-flex;
        align-items: center;
        border: 2px solid #12b897;
    }
    .inputPriceNum{
        width: 60px;
        padding: 4px 0px;
    }
`;

export default GroupSummaryStyle;
