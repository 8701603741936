import styled from '@emotion/styled';

interface TourCardProps {
    mode?: any;
}

const TourCardStyle = styled('div')<TourCardProps>`
    .nameEventTicket {
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
        margin-bottom: ${(props) =>
            props.mode === 'Edit mode' ? '0px' : '20px'};
    }
    .iconAddComment {
        width: 100%;
        display: ${(props) => (props.mode === 'Edit mode' ? 'flex' : 'none')};
        justify-content: flex-end;
        position: relative;
        bottom: 60px;
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
`;

export default TourCardStyle;
