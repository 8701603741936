import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
    useLocation,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import IntroductionGoflTravel from './IntroductionGolfTravel';
import QouteNavigateCompoment from './QuoteNavigate';
import QuoteDetailPageStyle from './style';
import images from 'src/assets/image';
import { nameView } from 'src/const/enum';
import ItineraryInDetailComponent from './ItineraryInDetail';
import PricingAndBookingComponent from './PricingAndBooking';
import ImportantInformationComponent from './ImportantInformation';
import TermsAndCoditionsComponent from './TermsAndCoditions';
import { Button } from 'antd';
import { updateCheckRoleSuccess } from 'src/redux/actions/roleCheckAction';
import QuoteTitleAndInputUpdateComponent from 'src/components/viewQuoteTitle';
import ModalComponent from 'src/components/modalCustom';
import ModalConfirmComponent from './modalConfirm';
import { formatCurrency } from 'src/const/contain';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';

interface destopProps {
    Imange?: any;
    dataContenFull?: any;
    dataJsonChild?: any;
    onUpdate?: () => void;
}

const DeskTopQuoteDetailPage: React.FC<destopProps> = ({
    Imange,
    dataContenFull,
    dataJsonChild,
    onUpdate,
}) => {
    const [roleBtn, setRoleBtn] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState<any>('');
    const [childData, setChildData] = useState<any>({
        id: nameView.GOFL_TRAVEL_EXPERT,
        content: 'Introduction from your Golf Travel Expert',
    });
    const [dataJson, setDataJson] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modeModal, setModeModal] = useState<string>('');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const role: string = location?.state || '';
    const isRole = useSelector(roleSelector);

    useEffect(() => {
        dispatch(updateCheckRoleSuccess('Preview mode'));
        setMainImage(Imange);
        setDataJson(dataJsonChild);
    }, [Imange, dataJsonChild, dispatch, role]);

    const handleChildDataChange = (data: string) => {
        setChildData(data);
    };

    const changeRoleEdit = () => {
        setRoleBtn(!roleBtn);
        dispatch(updateCheckRoleSuccess('Edit mode'));
    };

    const ModalFinish = (valueChild: boolean) => {
        setRoleBtn(valueChild);
        dispatch(updateCheckRoleSuccess('Preview mode'));
    };

    const showModal = (value?: string) => {
        setIsModalVisible(true);
        if (value) {
            setModeModal(value);
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    // value data json
    const resConsultant = dataJson?.resConsultant;
    const quoteTitle = dataJson?.title;
    const roundedTotalDiscount = Math.round(
        Math.abs(Number(dataJson?.price?.totalDiscount || 0)),
    );

    return (
        <Router>
            <QuoteDetailPageStyle>
                <div className="deskTopView">
                    {mode === 'PreviewMode' && (
                        <div className="headerShowRole">
                            <div className="viewRole viewRoleMobile">
                                <div>
                                    <span className="styleContentRole">
                                        You're viewing quote in{' '}
                                    </span>
                                    <span className="styleRole">{isRole}</span>
                                </div>
                                <div className="viewBtnRole">
                                    {roleBtn && (
                                        <Button
                                            type="primary"
                                            className="btnReset"
                                            onClick={() => ModalFinish(false)}
                                            ghost
                                        >
                                            {'Reset Changes'}
                                        </Button>
                                    )}
                                    {!roleBtn ? (
                                        <Button
                                            type="primary"
                                            className="btnEdit"
                                            onClick={changeRoleEdit}
                                            ghost
                                        >
                                            {'Edit'}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className="btnEdit"
                                            onClick={() => showModal('save')}
                                            ghost
                                        >
                                            {'Save'}
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        className="btnSend"
                                        onClick={() => showModal('send')}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="headerPage">
                        <div className="logoHeader">
                            <img
                                alt=""
                                className="LogoIMG"
                                src={images.logoYGT}
                            />
                        </div>
                        {roundedTotalDiscount !== 0 ? (
                            <div className="viewPromotion">
                                <div className="contentBtnPromotion">
                                    <FontAwesomeIcon
                                        icon={faCoins}
                                        className="iconCoins"
                                    />
                                    <p>
                                        You’ve saved on your trip today with a £
                                        {formatCurrency(roundedTotalDiscount)}{' '}
                                        discount
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="headerTitle">
                        <div className="titleHeader ">
                            <QuoteTitleAndInputUpdateComponent
                                showRole={isRole}
                                quoteTitle={quoteTitle}
                                onUpdateValueInput={showModal}
                            />
                        </div>
                        <div className="titleview">{childData.content}</div>
                    </div>
                    <div className="viewShowInfor">
                        <div className="viewInforLeft">
                            <Switch>
                                <Route exact path="/quoteDetail">
                                    <Redirect to="/quoteDetail/introduction" />
                                </Route>
                                <Route
                                    path="/quoteDetail/introduction"
                                    component={(props: any) => (
                                        <IntroductionGoflTravel
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            onUpdateValueInput={showModal}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/itineraryInDetail"
                                    component={(props: any) => (
                                        <ItineraryInDetailComponent
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            onUpdateValueInput={showModal}
                                            roleBtnEditOnHeader={ModalFinish}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/pricingAndBooking"
                                    component={(props: any) => (
                                        <PricingAndBookingComponent
                                            {...props}
                                            dataReadyToBook={dataContenFull}
                                            dataJsonProp={dataJson}
                                            customProp={dataContenFull}
                                            image={mainImage}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/importantInformation"
                                    component={(props: any) => (
                                        <ImportantInformationComponent
                                            {...props}
                                            dataImportanInfor={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/termsAndCoditions"
                                    component={(props: any) => (
                                        <TermsAndCoditionsComponent
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                        />
                                    )}
                                />
                                <Route
                                    render={() => (
                                        <Redirect to="/quoteDetail/introduction" />
                                    )}
                                />
                            </Switch>
                        </div>
                        <div className="viewInforRight">
                            <QouteNavigateCompoment
                                onDataChange={handleChildDataChange}
                                dataJsonResConsultant={resConsultant}
                                groupData={dataJson}
                            />
                        </div>
                    </div>
                </div>
                <ModalComponent
                    width={500}
                    visible={isModalVisible}
                    onClose={handleClose}
                    title="Confirmation"
                    content={
                        <ModalConfirmComponent
                            quoteTitle={quoteTitle}
                            close={handleClose}
                            roleBtn={ModalFinish}
                            modeView={modeModal}
                            updateData={onUpdate}
                        />
                    }
                />
            </QuoteDetailPageStyle>
        </Router>
    );
};

export default DeskTopQuoteDetailPage;
