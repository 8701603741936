import React, { useState } from 'react';
import ModalEditCommentStyle from './style';
import { Button, Spin } from 'antd';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { toast } from 'react-toastify';
import { apiPutPriceAgentComment } from 'src/api/apiPutPriceAgentComment';

interface ModalTourDestination {
    quoteTuorName?: string;
    commentValue?: any;
    optionEvent?: any;
    close?: () => void;
    changeShowEdit?: () => void;
    refetch?: () => void;
    additional?: any;
    resetLoad?: () => void;
}
const ModalEditComment: React.FC<ModalTourDestination> = ({
    quoteTuorName,
    commentValue,
    optionEvent,
    close,
    changeShowEdit,
    refetch,
    additional,
    resetLoad,
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const idItinerary = queryParams.get('id') || 0;
    const [loading, setLoading] = useState<boolean>(false);

    const { mutate } = useMutationApi<{ id: number | string; param: any }, any>(
        ({ id, param }) => apiPutPriceAgentComment(id, param),
        {
            onSuccess: (response) => {
                toast.success(`Your comment has been ${optionEvent}.`);
                refetch?.();
                changeShowEdit?.();
                close?.();
                setLoading(false);
                resetLoad?.();
            },
            onError: (error) => {
                toast.error(`Failed to ${optionEvent} your comment.`);
                close?.();
                setLoading(false);
                resetLoad?.();
            },
        },
    );

    const dataParam = {
        priceAgentComment: commentValue,
    };

    const dataAdditionalParam = {
        additionalInfo: commentValue,
    };
    const dataParamDelete = {
        priceAgentComment: '',
    };
    const dataParamAdditionalDelete = {
        additionalInfo: '',
    };

    const createComment = () => {
        mutate({
            id: Number(idItinerary),
            param: additional ? dataAdditionalParam : dataParam,
        });
        setLoading(true);
    };

    const deleteComment = () => {
        mutate({
            id: Number(idItinerary),
            param: additional ? dataParamAdditionalDelete : dataParamDelete,
        });
        setLoading(true);
    };

    return (
        <ModalEditCommentStyle>
            <Spin spinning={loading} delay={500}>
                <div>
                    <div className="contentModal">
                        Are you sure to{' '}
                        {optionEvent === 'Delete' ? 'delete' : 'save'} this
                        comment for the quote{' '}
                        <span className="quoteTitle">{quoteTuorName}</span>
                    </div>
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={close}
                            ghost
                        >
                            {optionEvent === 'Delete'
                                ? 'Cancel'
                                : 'Back to Edit'}
                        </Button>
                        {optionEvent === 'Create' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={createComment}
                            >
                                Save
                            </Button>
                        )}
                        {optionEvent === 'Edit' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={createComment}
                            >
                                Save
                            </Button>
                        )}
                        {optionEvent === 'Delete' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={deleteComment}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </Spin>
        </ModalEditCommentStyle>
    );
};

export default ModalEditComment;
