import styled from '@emotion/styled';
interface HotelCardProps {
    mode?: any;
}

const HotelCardStyle = styled('div')<HotelCardProps>`
    .mobileView {
        display: none;
    }
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
        margin-right: 10px;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }
    .iconDetailGolf {
        visibility: hidden;
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        margin-bottom: ${(props) =>
            props.mode === 'Edit mode' ? '0px' : '20px'};
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
    }
    .imgHotelOrGofl {
        width: 171px;
        height: 171px;
        gap: 16px;
        border-radius: 6px;
        margin-right: 24px;
    }
    .viewInforHotel {
        display: flex;
        width: 85%;
    }
    .reviewSeller {
        width: 100%;
        justify-content: space-between;
    }
    .iconAddComment {
        width: 100%;
        display: ${(props)=>(props.mode ==='Edit mode' ?'flex':'none')};
        justify-content: flex-end;
        position: relative;
        bottom: 122px;
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
    @media screen and (max-width: 1200px) {
        .hotelAndGoflViewMobile {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        .desktopView {
            display: none;
        }
        .mobileView {
            display: block;
        }
        .hotelAndGoflViewMobile {
            display: block;
            border-radius: 4px;
            background: #fff;
            box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
            margin-bottom: 27px;
        }
        .imgHotelOrGofl {
            width: 100%;
            height: 223px;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .bodyViewMobileCard {
            padding: 19px;
        }
        .headerMobile {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .mobileDiscription {
            display: flex;
        }
        .iconDetail {
            color: #12b897;
            margin-right: 22px;
        }
        .contentItemMobile {
            width: 78%;
        }
        .btnSeeDetail {
            margin-top: 13px;
        }
        .reviewSeller {
            width: 100%;
            justify-content: space-between;
            padding: 20px 30px 20px 30px;
            gap: 0px;
        }
    }
`;

export default HotelCardStyle;
