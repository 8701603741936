import React, { useCallback, useEffect, useRef, useState } from 'react';
import ViewQuoteTitleStyle from './styled';
import { Input, Popover } from 'antd';
import { updateQouteTitleSuccess } from 'src/redux/actions/quoteTitleChangeAction';
import { useDispatch } from 'react-redux';

interface CustomModalProps {
    showRole?: string | undefined;
    quoteTitle?: any;
    onUpdateValueInput?: (value: any) => void;
}

const QuoteTitleAndInputUpdateComponent: React.FC<CustomModalProps> = ({
    onUpdateValueInput,
    showRole,
    quoteTitle,
}) => {
    const dispatch = useDispatch();
    const [maxleng, setMaxLengh] = useState<number>(0);
    const [viewToInput, setChangeViewToInput] = useState<boolean>(false);
    const [inputvalue, setInputvalue] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let timeoutId: NodeJS.Timeout;
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                dispatch(updateQouteTitleSuccess(inputvalue));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, inputvalue, onUpdateValueInput]);

    useEffect(() => {
        setInputvalue(quoteTitle);
        const totalCharacters = quoteTitle?.length;
        setMaxLengh(totalCharacters);
    }, [quoteTitle, showRole]);

    const changerViewToInput = () => {
        setChangeViewToInput(!viewToInput);
    };

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (value.length <= 100) {
                setInputvalue(value);
            } else {
                setInputvalue(value.slice(0, 100));
            }
            const totalCharacters = value.length;
            setMaxLengh(totalCharacters);
        },
        [],
    );

    return (
        <ViewQuoteTitleStyle ref={inputRef}>
            {showRole === 'Edit mode' ? (
                <div>
                    {viewToInput ? (
                        <div>
                            <div className="editTitleHeader">
                                <Input
                                    className="inputEditTitle"
                                    variant="borderless"
                                    defaultValue={quoteTitle}
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={inputvalue}
                                />
                            </div>
                            {viewToInput && (
                                <div className='areaViewValidate'>
                                    <div className="validateView">
                                        {maxleng}/100
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Popover placement="rightTop" content={'Click to edit'}>
                            <div
                                className="editTitleHeader"
                                onClick={changerViewToInput}
                            >
                                {quoteTitle}
                            </div>
                        </Popover>
                    )}
                </div>
            ) : (
                <div>{quoteTitle}</div>
            )}
        </ViewQuoteTitleStyle>
    );
};

export default QuoteTitleAndInputUpdateComponent;
