import icons from 'src/assets/icon';

export const arrWhyContent = [
    {
        id: 1,
        icon: `${icons.golfTravelExpert}`,
        title: 'Golf Travel Experts',
        discription:
            'A dedicated expert in your chosen destination as standard',
    },
    {
        id: 2,
        icon: `${icons.bestPriceGuaranteed}`,
        title: 'Best Price Guaranteed',
        discription: 'Value you can trust and payment options you can rely on.',
    },
    {
        id: 3,
        icon: `${icons.unbeatableChoice}`,
        title: 'Unbeatable Choice',
        discription: '3500+ destinations in 24 countries chosen for you.',
    },
    {
        id: 4,
        icon: `${icons.industryLeadingProtection}`,
        title: 'Industry leading Protection ',
        discription: 'ABTOT & ATOL safeguards for greater peace of mind.',
    },
];

export const arrCaurosel = [
    {
        id: 1,
        piont: 9,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 2,
        piont: 10,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 3,
        piont: 11,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 4,
        piont: 8,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 5,
        piont: 8.5,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 6,
        piont: 9,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 7,
        piont: 8,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
    {
        id: 8,
        piont: 9.5,
        comment: 'excellent',
        disciption:
            'I can’t recommend it highly enough Brilliant experience from start to finish. Josh was great to deal with.',
        sign: 'Joe McGolfer',
    },
];

export const dataItineraryInDetail = [
    {
        id: 1,
        time: 'Day 1: Mon 5th August 2024',
        inforHotel: {
            nameHotel: 'Stay at The Belfry ',
            address: 'Warwickshire',
            timeforRent1: '4 Nights on Sunday 25th August 2025',
            opsRoom1: 'x2 Signature Room, Dinner, Bed & Breakfast',
            opsRoom2: 'x2 Superior Room, All-inclusive',
            timeforRent2: '3 Nights on Monday 26th August 2025',
            opsRoom3: 'x2 Signature Room (Single Occupancy), Half-Board',
            opsRoom4: 'x4 Superior Room, All-inclusive',
        },
        sellerComment:
            'I was here last month and there were some great places to eat in the evening. Excellent steak in the main bar',
        inforGofl: {
            namGofl: 'Round of Golf at The Brabazon ',
            address: 'The Belfry, Warwickshire',
            inforGround: '1 Round at The Brabazon for 8 golfers',
            dateTime1: ' 14:00 on 14/08/2024',
            people1: 4,
            dateTime2: '14:10 on 14/08/2024',
            people2: 4,
        },
    },
    {
        id: 2,
        time: 'Day 2: Tue 6th August 2024',
        inforHotel: {
            nameHotel: 'Stay at The Belfry ',
            address: 'Warwickshire',
            timeforRent1: '4 Nights on Sunday 25th August 2025',
            opsRoom1: 'x2 Signature Room, Dinner, Bed & Breakfast',
            opsRoom2: 'x2 Superior Room, All-inclusive',
            timeforRent2: '3 Nights on Monday 26th August 2025',
            opsRoom3: 'x2 Signature Room (Single Occupancy), Half-Board',
            opsRoom4: 'x4 Superior Room, All-inclusive',
        },
        sellerComment:
            'I was here last month and there were some great places to eat in the evening. Excellent steak in the main bar',
        inforGofl: {
            namGofl: 'Round of Golf at The Brabazon ',
            address: 'The Belfry, Warwickshire',
            inforGround: '1 Round at The Brabazon for 8 golfers',
            dateTime1: ' 14:00 on 14/08/2024',
            people1: 4,
            dateTime2: '14:10 on 14/08/2024',
            people2: 4,
        },
    },
];

export const arrGroup = [
    { id: 1, nameGroup: 'Group A', price: '£199' },
    { id: 2, nameGroup: 'Group B', price: '£199' },
    { id: 3, nameGroup: 'Group C', price: '£199' },
];

export const nameView = {
    GOFL_TRAVEL_EXPERT: 1,
    ITINERARY_IN_DETAIL: 2,
    PRICING_AND_BOOKING: 3,
    IMPORTANT_INFORMATION: 4,
    TERM_AND_CONDITION: 5,
};

export const showColumOnPage = [
    { key: '1', label: '10' },
    { key: '2', label: '20' },
    { key: '3', label: '30' },
];

export const listSearch = [
    { key: '1', label: 'Quote Ref' },
    { key: '2', label: 'Client Name' },
];

export const listSort = [
    { key: '1', label: 'Updated date' },
    { key: '2', label: 'Created date' },
];

export const dataSource = [
    {
        key: '1',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '2',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '3',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '4',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '5',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '6',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '7',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '8',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '9',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '10',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '11',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '12',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '13',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '14',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '15',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '16',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '17',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '18',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
    {
        key: '19',
        quoteRef: 'Mike',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'sent',
        version: 'V1',
    },
    {
        key: '20',
        quoteRef: 'John',
        departureDate: 'Sep 1 2024',
        quoteTitle: '10 Downing Street',
        clientName: 'Oliver Gunning',
        status: 'Draft',
        version: 'V1',
    },
];

export const dataVersion = [
    {
        key: '1',
        quoteTitle: 'Gunning Group’s Belfry Golf Trip',
        departureDate: 'Sep 1 2024',
        version: 'V1',
        vertionDetail: 'Updated intro text with ball offer',
        versionStatus: 'sent',
    },
    {
        key: '2',
        quoteTitle: 'Gunning Group’s Belfry Golf Trip',
        departureDate: 'Sep 1 2024',
        version: 'V2',
        vertionDetail: 'Tee time changes at Derby',
        versionStatus: 'Draft',
    },
    {
        key: '3',
        quoteTitle: 'Gunning Group’s Belfry Golf Trip',
        departureDate: 'Sep 1 2024',
        version: 'V3',
        vertionDetail: 'Updated intro text with ball offer',
        versionStatus: 'sent',
    },
];

export const dataGroupInfor = [
    {
        id: '1',
        NameGroup: 'Group A',
        price: '199',
        inforPeople: '4 people arriving on 14/08/2024',
        location: 'Staying at The Belfry, Signature Room (Single Occupancy)',
        sleep: '1 Night Dinner, Bed & Breakfast',
        gofl1: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
        gofl2: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
    },
    {
        id: '1',
        NameGroup: 'Group B',
        price: '199',
        inforPeople: '4 people arriving on 14/08/2024',
        location: 'Staying at The Belfry, Signature Room (Single Occupancy)',
        sleep: '1 Night Dinner, Bed & Breakfast',
        gofl1: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
        gofl2: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
    },
    {
        id: '1',
        NameGroup: 'Group C',
        price: '199',
        inforPeople: '4 people arriving on 14/08/2024',
        location: 'Staying at The Belfry, Signature Room (Single Occupancy)',
        sleep: '1 Night Dinner, Bed & Breakfast',
        gofl1: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
        gofl2: '1 Round at The Derby for 4 golfers on 14/08/2024 x4',
    },
];

export const dataDummyWBWItinerary = [
    {
        id: 1,
        content:
            'We re The Masters Experts, each year more than 1,000 golfers travel with us to Augusta.',
    },
    {
        id: 2,
        content:
            "Your trip is protected. We're members of ABTOT, ATOL & IATA licence holders.",
    },
    {
        id: 3,
        content:
            '100% of golfers who have travelled to The Masters with Your Golf Travel would recommend it to a friend.',
    },
    {
        id: 4,
        content: "We're rated 9.3 by TrustPilot with more than 3,000 reviews.",
    },
    {
        id: 5,
        content:
            'Our own Expert Event Staff - this is your dedicated concierge service whilst in Augusta.',
    },
    {
        id: 6,
        content:
            "Access to YGT's Hospitality & Ticket Marquee, just a short walk from the main Entrance.",
    },
    {
        id: 7,
        content:
            "Each Experience is custom-built to you - extend your trip, upgrade your flight, play Championship golf or just watch The Masters, we're here to help!",
    },
    {
        id: 8,
        content:
            "Whether it is a restaurant reservation, a tee-time or advice when in Augusta, we're on hand to help no matter how big or small your request is, whilst in The United States.",
    },
];

export const dataItinerary = [
    {
        typeDate: 'Day 1',
        date: 'Mon 5th August 2024',
        accommodation: [
            {
                name: 'The Belfry',
                address: 'Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                imageId: '5456466456',
                description:
                    'The Belfry Hotel & Resort is a legendary golf destination renowned for its three championship courses, including the iconic Brabazon Course, which has hosted the Ryder Cup four times. The resort offers state-of-the-art practice facilities and a PGA Academy, providing a world-class experience for golfers of all skill levels. With its luxurious accommodations, fine dining options, and stunning natural surroundings, The Belfry is the perfect getaway for golf enthusiasts seeking both challenge and relaxation in the heart of England',
                endDate: 'Monday 26th August 2025',
                roomType: [
                    {
                        id: 1,
                        nameRoomType: 'Signature Room',
                        NumOfPassenger: 2,
                        boardBasis: 'Bed and Breakfast',
                    },
                    {
                        id: 1,
                        nameRoomType: 'Superior Room',
                        NumOfPassenger: 2,
                        boardBasis: 'All-inclusive',
                    },
                ],
            },
            {
                name: 'The Belfry',
                address: 'Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                endDate: 'Monday 26th August 2025',
                imageId: '5456466456',
                description:
                    'The Belfry Hotel & Resort is a legendary golf destination renowned for its three championship courses, including the iconic Brabazon Course, which has hosted the Ryder Cup four times. The resort offers state-of-the-art practice facilities and a PGA Academy, providing a world-class experience for golfers of all skill levels. With its luxurious accommodations, fine dining options, and stunning natural surroundings, The Belfry is the perfect getaway for golf enthusiasts seeking both challenge and relaxation in the heart of England',
                roomType: [
                    {
                        id: 1,
                        nameRoomType: 'Signature Room',
                        NumOfPassenger: 2,
                        boardBasis: 'Bed and Breakfast',
                    },
                    {
                        id: 1,
                        nameRoomType: 'Superior Room',
                        NumOfPassenger: 2,
                        boardBasis: 'All-inclusive',
                    },
                ],
            },
        ],
        comment: [
            {
                subType: 'accommodation comment',
                text: 'Reasons to Visit The Belfry\r\n4 time Ryder Cup host and regular European Tour venue\nDesigned by Dave Thomas & renowned golf commentator Peter Alliss\nGreat nightlife with on-site Bel Air nightclub',
            },
            {
                subType: 'gofl comment',
                text: 'Reasons to Visit The Belfry\r\n4 time Ryder Cup host and regular European Tour venue\nDesigned by Dave Thomas & renowned golf commentator Peter Alliss\nGreat nightlife with on-site Bel Air nightclub',
            },
        ],
        golf: [
            {
                name: 'Round of Golf at The Brabazon ',
                address: 'The Belfry, Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                endDate: 'Monday 26th August 2025',
                rounds: 1,
                imageId: '5456466456',
                content:
                    'The Belfry Hotel & Resort is a legendary golf destination renowned for its three championship courses, including the iconic Brabazon Course, which has hosted the Ryder Cup four times. The resort offers state-of-the-art practice facilities and a PGA Academy, providing a world-class experience for golfers of all skill levels. With its luxurious accommodations, fine dining options, and stunning natural surroundings, The Belfry is the perfect getaway for golf enthusiasts seeking both challenge and relaxation in the heart of England',
                description: 'Green Fee - 18 Holes',
                detailRound: [
                    {
                        time: '14:00 on 14/08/2024',
                        numOfPassenger: 4,
                    },
                ],
            },
        ],
        flight: [
            {
                name: 'Your Outbound Flights',
                passenger: 4,
                departure: {
                    code: 'LGW',
                    date: '2024-10-09T19:15:00.000Z',
                    name: 'London Gatwick',
                },
                arrival: {
                    code: 'BHD',
                    date: '2024-10-09T20:45:00.000Z',
                    name: 'Belfast City',
                },
                airline: {
                    number: 'EZY893',
                    operatedBy: 'EasyJet (EZY)',
                },
                extra: {
                    luggage: 1,
                    golfBags: 4,
                },
            },
        ],
        carHire: [
            {
                name: 'Pick Up Your Hire Car',
                passenger: 8,
                departure: {
                    discription:
                        'Pick-up vehicle from Charlotte (Douglas International)',
                    time: '13:00',
                },
                carType: 'Chevrolet Suburban',
            },
        ],
        transfer: [
            {
                name: 'Shuttle Transfer',
                passenger: 2,
                departure: {
                    discription:
                        'Holiday Express Inn Downtown Augusta To National Golf Course',
                    time: '13:00',
                },
                carType: 'Chevrolet Suburban',
            },
        ],
        TourTicket: [
            {
                ticketName: 'Master Practice Round Ticket - Wednesday',
            },
            {
                ticketName:
                    'Masters Tournament Hospitality & Admission to The Manor House Hospitality Complex',
            },
        ],
    },
    {
        typeDate: 'Day 2',
        date: 'Tuesday 5th August 2024',
        imageId: '5456466456',
        description:
            'The Belfry Hotel & Resort is a legendary golf destination renowned for its three championship courses, including the iconic Brabazon Course, which has hosted the Ryder Cup four times. The resort offers state-of-the-art practice facilities and a PGA Academy, providing a world-class experience for golfers of all skill levels. With its luxurious accommodations, fine dining options, and stunning natural surroundings, The Belfry is the perfect getaway for golf enthusiasts seeking both challenge and relaxation in the heart of England',
        accommodation: [
            {
                name: 'The Belfry',
                address: 'Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                endDate: 'Monday 26th August 2025',
                roomType: [
                    {
                        id: 1,
                        nameRoomType: 'Signature Room',
                        NumOfPassenger: 2,
                        boardBasis: 'Bed and Breakfast',
                    },
                    {
                        id: 1,
                        nameRoomType: 'Superior Room',
                        NumOfPassenger: 2,
                        boardBasis: 'All-inclusive',
                    },
                ],
            },
            {
                name: 'The Belfry',
                address: 'Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                endDate: 'Monday 26th August 2025',
                roomType: [
                    {
                        id: 1,
                        nameRoomType: 'Signature Room',
                        NumOfPassenger: 2,
                        boardBasis: 'Bed and Breakfast',
                    },
                    {
                        id: 1,
                        nameRoomType: 'Superior Room',
                        NumOfPassenger: 2,
                        boardBasis: 'All-inclusive',
                    },
                ],
            },
        ],
        comment: [
            {
                subType: 'accommodation comment',
                text: 'Reasons to Visit The Belfry\r\n4 time Ryder Cup host and regular European Tour venue\nDesigned by Dave Thomas & renowned golf commentator Peter Alliss\nGreat nightlife with on-site Bel Air nightclub',
            },
            {
                subType: 'gofl comment',
                text: 'Reasons to Visit The Belfry\r\n4 time Ryder Cup host and regular European Tour venue\nDesigned by Dave Thomas & renowned golf commentator Peter Alliss\nGreat nightlife with on-site Bel Air nightclub',
            },
        ],
        golf: [
            {
                name: 'Round of Golf at The Brabazon ',
                address: 'The Belfry, Warwickshire',
                duration: 4,
                startDate: 'Sunday 25th August 2025',
                endDate: 'Monday 26th August 2025',
                rounds: 1,
                description: 'Green Fee - 18 Holes',
                detailRound: [
                    {
                        time: '14:00 on 14/08/2024',
                        numOfPassenger: 4,
                    },
                ],
            },
        ],
        flight: {
            name: 'Your Outbound Flights',
            passenger: 4,
            departure: {
                code: 'LGW',
                date: '2024-10-09T19:15:00.000Z',
                name: 'London Gatwick',
            },
            arrival: {
                code: 'BHD',
                date: '2024-10-09T20:45:00.000Z',
                name: 'Belfast City',
            },
            airline: {
                number: 'EZY893',
                operatedBy: 'EasyJet (EZY)',
            },
            extra: {
                luggage: 1,
                golfBags: 4,
            },
        },
        carHire: {
            name: 'Pick Up Your Hire Car',
            passenger: 8,
            departure: {
                discription:
                    'Pick-up vehicle from Charlotte (Douglas International)',
                time: '13:00',
            },
            carType: 'Chevrolet Suburban',
        },
        transfer: {
            name: 'Shuttle Transfer',
            passenger: 2,
            departure: {
                discription:
                    'Holiday Express Inn Downtown Augusta To National Golf Course',
                time: '13:00',
            },
            carType: 'Chevrolet Suburban',
        },
    },
];
const YourMastersExperience = {
    name: ' Your Masters Experience 2025 At A Glance',
    itineraryComment:
        'Masters entry ticket for Tournament Day Friday or Saturday or Sunday (1 day) \r Shuttle from your hotel to AGNGC \r Hospitality on the day \r Masters Gift \r Invite to the YGT Private Party on Saturday \r Evening if Masters on Saturday \r YGT Concierge Guest services in Augusta',
};

const pleaseRead = [
    {
        name: 'Marriott Golf Resort',
        content:
            'Please note that check-in will not be permitted without a valid credit card or debit card. On arrival all guests will be required to provide a valid credit card or debit card to guarantee extras & incidentals. \r This card will be utili6ed to administer a pre-authorisation charge of £50 per person. \r The Belfry reserves the right, if required, to pair your group with other players so that all tee times are complete with 4 player \r\
\r All guests other than our spa packages will be able to use the Leisure Facilities (excluding the Lire & Ice) from 3pm on the day of arrival until 11am on the day that they depart. Should they wish to use the facilities outside of the times given, guests can purchase a pass for earlier / later access.\r\
Dinner reservations must be pre-booked, if customers require a dinner reservations please advise your sales agent,who will add the request to your booking.',
    },
    {
        name: 'Marriott Golf Resort',
        content:
            'Please note that check-in will not be permitted without a valid credit card or debit card. On arrival all guests will be required to provide a valid credit card or debit card to guarantee extras & incidentals. \r This card will be utili6ed to administer a pre-authorisation charge of £50 per person. \r The Belfry reserves the right, if required, to pair your group with other players so that all tee times are complete with 4 player \r\
\r All guests other than our spa packages will be able to use the Leisure Facilities (excluding the Lire & Ice) from 3pm on the day of arrival until 11am on the day that they depart. Should they wish to use the facilities outside of the times given, guests can purchase a pass for earlier / later access.\r\
Dinner reservations must be pre-booked, if customers require a dinner reservations please advise your sales agent,who will add the request to your booking.',
    },
];
