import React from 'react';
import ViewYourMasterExperientComponentStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import useQueryApi from 'src/hooks/useApiQuery';
import { getItinerary } from 'src/api/apiGetItinerary';

const YourMasterExperientComponent = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const idItinerary = queryParams.get('id') || undefined;

    const { data: dataItinerary } = useQueryApi(
        ['idItinerary', idItinerary],
        () => getItinerary(idItinerary),
        {
            enabled: !!idItinerary,
        },
    );
    const data = (dataItinerary as { atAGlance: any })?.atAGlance || [];
    const contentAtAGlance = data?.itineraryComments;
    const tourName = data?.tourName;
    const result = tourName?.split(' - ')[0];

    return (
        <div>
            {data?.length !== 0 && (
                <ViewYourMasterExperientComponentStyle>
                    <div className="Header">Your {result} At A Glance</div>
                    <div className="Body">
                        <div>
                            {contentAtAGlance?.map((item: any, index: any) => {
                                let icon = faTrophy;
                                return (
                                    <div key={index}>
                                        <div className="detailInforAtAGlance">
                                            <div className="centerIconAtAGlance">
                                                <FontAwesomeIcon
                                                    className="iconDetailAtAGlance"
                                                    icon={icon}
                                                />
                                            </div>
                                            <div className="titleDetailInforAtAGlance">
                                                {item}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </ViewYourMasterExperientComponentStyle>
            )}
        </div>
    );
};

export default YourMasterExperientComponent;
