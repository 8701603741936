import { AnyAction } from 'redux';
import * as ActionType from '../actions/quoteIntroAction';

export interface QuoteIntroState {
    quoteIntro: any;
}
const initialState: QuoteIntroState = {
    quoteIntro: '',
};
export const editQuoteIntroReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.UPDATE_QUOTE_INTRO_ACTION:
            return {
                ...state,
                quoteIntro: action.payload,
            };
        default:
            return state;
    }
};