import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faPhone } from '@fortawesome/free-solid-svg-icons';
import IntroductionGoflTravel from './IntroductionGolfTravel';
import QouteNavigateCompoment from './QuoteNavigate';
import images from 'src/assets/image';
import { nameView } from 'src/const/enum';
import ItineraryInDetailComponent from './ItineraryInDetail';
import PricingAndBookingComponent from './PricingAndBooking';
import ImportantInformationComponent from './ImportantInformation';
import TermsAndCoditionsComponent from './TermsAndCoditions';
import { Button, Skeleton } from 'antd';
import { updateCheckRoleSuccess } from 'src/redux/actions/roleCheckAction';
import QuoteDetailPageStyle from './style';
import ModalComponent from 'src/components/modalCustom';
import ModalConfirmComponent from './modalConfirm';
import { formatCurrency, formatPhoneNumber } from 'src/const/contain';
import QuoteTitleAndInputUpdateComponent from 'src/components/viewQuoteTitle';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';

interface mobileProps {
    Imange?: any;
    dataContenFull?: any;
    dataJsonChild?: any;
    onUpdate?: () => void;
}

const MobileQouteDetailPage: React.FC<mobileProps> = ({
    Imange,
    dataContenFull,
    dataJsonChild,
    onUpdate,
}) => {
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState<any>('');
    const [childData, setChildData] = useState<any>({
        id: nameView.GOFL_TRAVEL_EXPERT,
        content: 'Introduction from your Golf Travel Expert',
    });
    const [dataJson, setDataJson] = useState<any>();
    const [roleBtn, setRoleBtn] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modeModal, setModeModal] = useState<string>('');
    const location: any = useLocation();
    const role: string = location?.state || '';
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const mode = queryParams.get('mode');
    const isRole = useSelector(roleSelector);
    const tourNameTakeImage: string = dataJsonChild?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    const checkEvent = (dataJsonChild as { isEvent: any })?.isEvent;

    //id image tour event
    const defaultImage = dataContenFull?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        dataContenFull?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any =
        dataContenFull?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = dataContenFull?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        dataContenFull?.solheimCupImage?.sys?.id || null;

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    useEffect(() => {
        dispatch(updateCheckRoleSuccess('Preview mode'));
        setMainImage(Imange);
        setDataJson(dataJsonChild);
    }, [Imange, dataContenFull, dataJsonChild, role]);

    const handleChildDataChange = (data: string) => {
        setChildData(data);
    };

    const changeRoleEdit = () => {
        setRoleBtn(!roleBtn);
        dispatch(updateCheckRoleSuccess('Edit mode'));
    };

    const ModalFinish = (valueChild: boolean) => {
        setRoleBtn(valueChild);
        dispatch(updateCheckRoleSuccess('Preview mode'));
    };

    const showModal = (value?: string) => {
        setIsModalVisible(true);
        if (value) {
            setModeModal(value);
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };
    // value data json
    const resConsultant = dataJson?.resConsultant;
    const imageDataJson = dataJson?.mainCourse?.image;
    const quoteTitle = dataJson?.title;
    const roundedTotalDiscount = Math.round(
        Math.abs(Number(dataJson?.price?.totalDiscount || 0)),
    );
    const phoneNumber = dataJson?.resConsultant?.telephone;

    return (
        <QuoteDetailPageStyle>
            {mode === 'PreviewMode' && (
                <div className="headerShowRoleMobile">
                    <div className="viewRoleMobile">
                        <div className="viewContentRoleMobile">
                            <span className="styleContentRoleMobile">
                                You're viewing quote in{' '}
                            </span>
                            <span className="styleRoleMobile">{isRole}</span>
                        </div>
                        <div className="viewBtnRoleMobile">
                            {roleBtn && (
                                <Button
                                    type="primary"
                                    className="btnResetMobile"
                                    onClick={() => ModalFinish(false)}
                                    size="small"
                                    ghost
                                >
                                    {'Reset Changes'}
                                </Button>
                            )}
                            {!roleBtn ? (
                                <Button
                                    type="primary"
                                    className="btnEditMobile"
                                    onClick={changeRoleEdit}
                                    size="small"
                                    ghost
                                >
                                    {'Edit'}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    className="btnEditMobile"
                                    onClick={() => showModal('save')}
                                    size="small"
                                    ghost
                                >
                                    {'Save'}
                                </Button>
                            )}

                            <Button
                                type="primary"
                                className="btnSendMobile"
                                size="small"
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div className="headerPageMobile">
                <div className="logoHeaderMobile">
                    <img
                        alt=""
                        className="LogoIMGMobile"
                        src={images.logoYGT}
                    />
                </div>
                {roundedTotalDiscount !== 0 ? (
                    <div className="viewPromotionMobile">
                        <div className="contentBtnPromotionMobile">
                            <p>
                                Promo: £{formatCurrency(roundedTotalDiscount)}{' '}
                                OFF when you book today
                            </p>
                            <FontAwesomeIcon icon={faCoins} />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="headerTitleMobile">
                <div className="titleHeaderMobile ">
                    <QuoteTitleAndInputUpdateComponent
                        showRole={isRole}
                        quoteTitle={quoteTitle}
                        onUpdateValueInput={showModal}
                    />
                </div>
                <div className="titleviewMobile">{childData.content}</div>
            </div>
            {(childData.id === nameView.PRICING_AND_BOOKING ||
                childData.id === nameView.GOFL_TRAVEL_EXPERT ||
                childData.id === nameView.IMPORTANT_INFORMATION ||
                childData.id === nameView.TERM_AND_CONDITION) && (
                <div>
                    {(checkEvent === false || type === 'Standard') && (
                        <div className="viewShowImageMobile">
                            {mainImage || imageDataJson ? (
                                <img
                                    alt=""
                                    className="imageGoftMobile"
                                    src={
                                        imageDataJson
                                            ? imageDataJson
                                            : mainImage
                                    }
                                />
                            ) : (
                                <Skeleton.Image
                                    className="imageGoftMobile"
                                    active={true}
                                    style={{ width: '100%', height: 224 }}
                                />
                            )}
                        </div>
                    )}
                    {(checkEvent === true || type === 'Event') && (
                        <div className="viewShowImageMobile">
                            {imageUrlTourTheOpen ? (
                                <img
                                    alt=""
                                    className="imageGoftMobile"
                                    src={imageUrlTourTheOpen}
                                />
                            ) : (
                                <Skeleton.Image
                                    className="imageGoftMobile"
                                    active={true}
                                    style={{ width: '100%', height: 224 }}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className="viewShowInfor">
                <div className="viewInforRight">
                    <QouteNavigateCompoment
                        onDataChange={handleChildDataChange}
                        dataJsonResConsultant={resConsultant}
                        groupData={dataJson}
                    />
                </div>
                <div className="viewInforLeft">
                    <Switch>
                        <Route exact path="/quoteDetail">
                            <Redirect to="/quoteDetail/introduction" />
                        </Route>
                        <Route
                            path="/quoteDetail/introduction"
                            component={(props: any) => (
                                <IntroductionGoflTravel
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    onUpdateValueInput={showModal}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/itineraryInDetail"
                            component={(props: any) => (
                                <ItineraryInDetailComponent
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    onUpdateValueInput={showModal}
                                    roleBtnEditOnHeader={ModalFinish}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/pricingAndBooking"
                            component={(props: any) => (
                                <PricingAndBookingComponent
                                    {...props}
                                    dataReadyToBook={dataContenFull}
                                    dataJsonProp={dataJson}
                                    customProp={dataContenFull}
                                    image={mainImage}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/importantInformation"
                            component={(props: any) => (
                                <ImportantInformationComponent
                                    {...props}
                                    dataImportanInfor={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/termsAndCoditions"
                            component={(props: any) => (
                                <TermsAndCoditionsComponent
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                />
                            )}
                        />
                        <Route
                            render={() => (
                                <Redirect to="/quoteDetail/introduction" />
                            )}
                        />
                    </Switch>
                </div>
            </div>
            <div className="btnPhone">
                <Button
                    type="primary"
                    className="btnPhoneCall"
                    onClick={() =>
                        (window.location.href = `tel:${phoneNumber}`)
                    }
                >
                    Call Me On {formatPhoneNumber(phoneNumber || '08000436644')}
                    <FontAwesomeIcon icon={faPhone} /> To Book
                </Button>
            </div>
            <ModalComponent
                width={500}
                visible={isModalVisible}
                onClose={handleClose}
                title="Confirmation"
                content={
                    <ModalConfirmComponent
                        quoteTitle={quoteTitle}
                        close={handleClose}
                        roleBtn={ModalFinish}
                        modeView={modeModal}
                        updateData={onUpdate}
                    />
                }
            />
        </QuoteDetailPageStyle>
    );
};

export default React.memo(MobileQouteDetailPage);
