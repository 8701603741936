import React, { useState } from 'react';
import GolfsCardStyle from './style';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faGolfBallTee,
    faUser,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';

interface GolfsCardProps {
    dataGolf?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
}

const GolfsCardComponent: React.FC<GolfsCardProps> = ({
    dataGolf,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const nameOfGolf = dataGolf?.name || '';
    // const addressGolf =
    //     `${dataGolf?.address?.companyName}${
    //         dataGolf?.address?.town && ','
    //     }${' '}${dataGolf?.address?.town}` || '';
    const numRound = dataGolf?.rounds || '';
    const detailRound = dataGolf?.detailRound || [];
    const totalPassengers = detailRound.reduce(
        (total: any, round: any) => total + round.numOfPassenger,
        0,
    );
    const buggies = dataGolf?.buggies || 0;
    // const commentRound = dataGolf?.comments || [];
    const image = dataGolf?.image || '';
    const discriptionGolf = dataGolf?.description || '';
    const agentComments = dataGolf?.agentComments || [];
    const itemId = dataGolf?.id;

    return (
        <GolfsCardStyle mode={isRole}>
            <div className="desktopView">
                <div>
                    <div className="hotelAndGoflView">
                        <div className="viewInforGolf">
                            <img
                                alt=""
                                className="imgHotelOrGofl"
                                src={image ? image : images.hotelImage}
                            />
                            <div>
                                <div className="titleItem">
                                    Round of Golf at {nameOfGolf}{' '}
                                </div>
                                {/* <div className="addressHotelOrGolf">
                                {addressGolf}
                            </div> */}
                                <div className="contentInfor">
                                    {numRound} Round
                                    {Number(numRound) > 1 ? 's' : ''} at{' '}
                                    {nameOfGolf} for {totalPassengers} golfer
                                    {Number(totalPassengers) > 1 ? 's' : ''}
                                </div>
                                <div className="viewItemDescription">
                                    {detailRound?.map(
                                        (item: any, index: any) => {
                                            return (
                                                <div
                                                    className="timeAndExtraRoundOfGolf"
                                                    key={index}
                                                >
                                                    <div>
                                                        <FontAwesomeIcon
                                                            className="iconDetail"
                                                            icon={faFlag}
                                                        />
                                                    </div>
                                                    <div className="timeOfRound extrabuggy">
                                                        {moment
                                                            .utc(item?.time)
                                                            .format('HH:mm') ===
                                                        '00:00'
                                                            ? 'Time TBC'
                                                            : moment
                                                                  .utc(
                                                                      item?.time,
                                                                  )
                                                                  .format(
                                                                      'HH:mm',
                                                                  )}{' '}
                                                        on{' '}
                                                        {moment
                                                            .utc(item?.time)
                                                            .format(
                                                                'DD/MM/YYYY',
                                                            )}
                                                    </div>
                                                    <div className="detailRoundGolf">
                                                        {' '}
                                                        x {
                                                            item?.numOfPassenger
                                                        }{' '}
                                                        {Number(
                                                            item?.numOfPassenger,
                                                        ) > 1
                                                            ? 'golfers'
                                                            : 'golfer'}
                                                        {moment
                                                            .utc(item?.time)
                                                            .format('HH:mm') !==
                                                            '00:00' && (
                                                            <FontAwesomeIcon
                                                                className="iconDetail endIcon"
                                                                icon={
                                                                    faUserGroup
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}

                                    {!buggies ? null : (
                                        <div className="timeAndExtraRoundOfGolf">
                                            <div>
                                                <FontAwesomeIcon
                                                    className="iconDetail"
                                                    icon={faGolfBallTee}
                                                />
                                            </div>
                                            <div className="timeOfRound extrabuggy">
                                                Buggies
                                            </div>
                                            <div className="detailRoundGolf">
                                                x {buggies}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="iconAndBtnItem">
                            <FontAwesomeIcon
                                className="iconHotelorGolf"
                                icon={faGolfBallTee}
                            />
                            {image && discriptionGolf && (
                                <div
                                    className="btnSeeDetail"
                                    onClick={() => showModal()}
                                >
                                    See details
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="iconAddComment"
                        onClick={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                    >
                        <img
                            alt=""
                            className="iconAdd"
                            src={icons.addComment}
                        />
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Golf"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Golf"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </div>
            <div className="mobileView">
                <div className="hotelAndGoflViewMobile">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={images.hotelImage}
                    />
                    <div className="bodyViewMobileCard">
                        <div className="headerMobile">
                            <div className="titleItem">
                                Round of Golf at {nameOfGolf}{' '}
                            </div>
                            <FontAwesomeIcon
                                className="iconHotelorGolf"
                                icon={faGolfBallTee}
                            />
                        </div>
                        {/* <div className="addressHotelOrGolf">{addressGolf}</div> */}
                        <div className="contentInfor">
                            {numRound} Round at {nameOfGolf} for{' '}
                            {totalPassengers} golfers
                        </div>
                        {detailRound?.map((item: any, index: any) => {
                            return (
                                <div
                                    className="discriptRoundOfGofl"
                                    key={index}
                                >
                                    <div>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faFlag}
                                        />
                                    </div>
                                    <div className="timeOfRound">
                                        {moment
                                            .utc(item?.time)
                                            .format('HH:mm') === '00:00'
                                            ? 'TBC'
                                            : moment
                                                  .utc(item?.time)
                                                  .format('HH:mm')}{' '}
                                        on{' '}
                                        {moment
                                            .utc(item?.time)
                                            .format('DD/MM/YYYY')}
                                    </div>
                                    <div className="detailRoundGolf">
                                        {' '}
                                        x {item?.numOfPassenger} {'golfers'}
                                        <FontAwesomeIcon
                                            className="iconDetail endIcon"
                                            icon={faUser}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {!buggies ? null : (
                                        <div className="discriptRoundOfGofl">
                                            <div>
                                                <FontAwesomeIcon
                                                    className="iconDetail"
                                                    icon={faGolfBallTee}
                                                />
                                            </div>
                                            <div className="timeOfRound extrabuggy">
                                                Buggies
                                            </div>
                                            <div className="detailRoundGolf">
                                                x {buggies}
                                            </div>
                                        </div>
                                    )}
                        {image && discriptionGolf && (
                            <div
                                className="btnSeeDetail"
                                onClick={() => showModal()}
                            >
                                See details
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Golf"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Golf"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={` Round of Golf at ${nameOfGolf}`}
                content={
                    <ModalTouristDetination
                        image={image}
                        discription={discriptionGolf}
                    />
                }
            />
        </GolfsCardStyle>
    );
};

export default GolfsCardComponent;
