import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const useMutationApi = <T, U>(
    apiFunction: (data: T) => Promise<AxiosResponse<U>>,
    options?: UseMutationOptions<U, Error, T>,
): UseMutationResult<U, Error, T> => {
    return useMutation<U, Error, T>({
        mutationFn: async (data: T) => {
            const response = await apiFunction(data);
            return response.data;
        },
        ...options,
    });
};
