import React from 'react';
import TermsAndCoditionsStyle, {
    ErrorStyle,
    TermsAndCoditionsMainImageStyle,
} from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getTermAndCondition } from 'src/api/apiGetTermAndCondition';
import { marked } from 'marked';
import parse from 'html-react-parser';
import NotFound from 'src/components/notFoundData';
import { Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { getImageApi } from 'src/api/apiGetImage';

interface TermsAndCoditionsProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
}

const TermsAndCoditionsComponent: React.FC<TermsAndCoditionsProps> = ({
    customProp,
    dataJsonProp,
    image,
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    //id image tour event
    const defaultImage = customProp?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;
    const checkEvent = dataJsonProp?.isEvent;

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    const {
        data: dataTermAndCondition,
        isLoading,
        isError,
    } = useQueryApi(['idItinerary'], () => getTermAndCondition());

    const TermAndCondition =
        (dataTermAndCondition as any)?.fields?.body ||
        '<p>No content available.</p>';
    const htmlContent = marked(TermAndCondition);

    const renderError = () => (
        <div className="error">
            <NotFound content={<div>No data to display</div>} />
        </div>
    );

    const renderLoading = () => (
        <ErrorStyle>
            <Skeleton
                className="imgGoflTravel"
                active={true}
                style={{ width: '100%' }}
            />
        </ErrorStyle>
    );

    return (
        <>
            <TermsAndCoditionsMainImageStyle>
                {(checkEvent === false || type === 'Standard') && (
                    <div>
                        {!image ? (
                            <div className="viewMainImage">
                                <Skeleton.Image
                                    className="imgGoflTravel"
                                    active={true}
                                    style={{ width: '100%', height: 413 }}
                                />
                            </div>
                        ) : (
                            <div className="viewMainImage">
                                <img
                                    alt=""
                                    className="imgGoflTravel"
                                    src={image}
                                />
                            </div>
                        )}
                    </div>
                )}
                {(checkEvent === true || type === 'Event') && (
                    <div>
                        {!imageUrlTourTheOpen ? (
                            <div className="viewMainImage">
                                <Skeleton.Image
                                    className="imgGoflTravel"
                                    active={true}
                                    style={{ width: '100%', height: 413 }}
                                />
                            </div>
                        ) : (
                            <div className="viewMainImage">
                                <img
                                    alt=""
                                    className="imgGoflTravel"
                                    src={imageUrlTourTheOpen}
                                />
                            </div>
                        )}
                    </div>
                )}
            </TermsAndCoditionsMainImageStyle>
            {isError ? (
                renderError()
            ) : isLoading ? (
                renderLoading()
            ) : (
                <TermsAndCoditionsStyle>
                    <div className="title">Terms & Conditions</div>
                    <div className="content">{parse(String(htmlContent))}</div>
                    <div className="content">
                        <a
                            href="https://www.yourgolftravel.com/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            See full terms and conditions{' '}
                            <FontAwesomeIcon
                                className="iconLink"
                                icon={faArrowUpRightFromSquare}
                            />
                        </a>
                    </div>
                </TermsAndCoditionsStyle>
            )}
        </>
    );
};

export default TermsAndCoditionsComponent;
