import styled from '@emotion/styled';

const ViewYourMasterExperientComponentStyle = styled('div')`
    width: 100%;
    background-color: #ffffff;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 17px 20px 17px 20px;
    margin-bottom: 20px;
    .Header {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.4px;
    }
    .Body {
        padding: 15px;
    }
    .detailInforAtAGlance {
        margin-bottom: 6px;
        display: flex;
    }
    .titleDetailInforAtAGlance {
        color: #000;
        font-family: "Lato";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .centerIconAtAGlance {
        padding-top: 6px;
    }
    .iconDetailAtAGlance {
        color: #12b897;
        margin-right: 10px;
    }
`;

export default ViewYourMasterExperientComponentStyle;
